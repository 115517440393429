import "./App.css";
import { Route, Routes } from "react-router-dom";
import React from "react";
import Landing from "./Landing";
import BioText from "./containers/BioText";
import AskMeQuestion from "./AskMeQuestion";
import PrivacyPolicy from "./Legal/PrivacyPolicy";
import TermsOfService from "./Legal/TermsOfService";
function App() {
  return (
    <Routes>
      <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
      <Route path="/tos" element={<TermsOfService />}></Route>
      <Route path="/instagram_fonts" element={<BioText />}></Route>
      <Route path="/:question_id/:origin" element={<AskMeQuestion />}></Route>
      <Route path="/:question_id/" element={<AskMeQuestion />}></Route>
      <Route path="/" element={<Landing />}></Route>
    </Routes>
  );
}

export default App;
