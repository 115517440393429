// Lunicode.js
// from lunicode.com
// on GitHub: https://github.com/combatwombat/Lunicode.js
// Copyright © 2012 Robert Gerlach - robsite.net
function Lunicode() {
  this.tools = {
    // Flip/rotate Text by 180°

    flip: {
      init: function () {
        // invert the map
        for (var i in this.map) {
          this.map[this.map[i]] = i;
        }
      },

      encode: function (text) {
        var ret = [],
          ch;

        for (var i = 0, len = text.length; i < len; i++) {
          ch = text.charAt(i);

          // combining diacritical marks: combine with previous character for ä,ö,ü,...
          if (
            i > 0 &&
            (ch == "\u0324" ||
              ch == "\u0317" ||
              ch == "\u0316" ||
              ch == "\u032e")
          ) {
            ch = this.map[text.charAt(i - 1) + ch];
            ret.pop();
          } else {
            ch = this.map[ch];
            if (typeof ch == "undefined") {
              ch = text.charAt(i);
            }
          }

          ret.push(ch);
        }

        return ret.reverse().join("");
      },

      // same as encode(), for now...
      decode: function (text) {
        var ret = [],
          ch;

        for (var i = 0, len = text.length; i < len; i++) {
          ch = text.charAt(i);

          // combining diacritical marks: combine with previous character for ä,ö,ü,...
          if (
            i > 0 &&
            (ch == "\u0324" ||
              ch == "\u0317" ||
              ch == "\u0316" ||
              ch == "\u032e")
          ) {
            ch = this.map[text.charAt(i - 1) + ch];
            ret.pop();
          } else {
            ch = this.map[ch];
            if (typeof ch == "undefined") {
              ch = text.charAt(i);
            }
          }

          ret.push(ch);
        }
        return ret.reverse().join("");
      },

      map: {
        // Thanks to
        // - David Faden: http://www.revfad.com/flip.html
        // - http://en.wikipedia.org/wiki/Transformation_of_text
        a: "\u0250",
        b: "q",
        c: "\u0254",
        d: "p",
        e: "\u01DD",
        f: "\u025F",
        g: "\u0253",
        h: "\u0265",
        i: "\u0131",
        j: "\u027E",
        k: "\u029E",
        l: "\u006C",
        m: "\u026F",
        n: "u",
        r: "\u0279",
        t: "\u0287",
        v: "\u028C",
        w: "\u028D",
        y: "\u028E",
        A: "\u2200",
        B: "ᙠ",
        C: "\u0186",
        D: "ᗡ",
        E: "\u018e",
        F: "\u2132",
        G: "\u2141",
        J: "\u017f",
        K: "\u22CA",
        L: "\u02e5",
        M: "W",
        P: "\u0500",
        Q: "\u038C",
        R: "\u1D1A",
        T: "\u22a5",
        U: "\u2229",
        V: "\u039B",
        Y: "\u2144",
        1: "\u21c2",
        2: "\u1105",
        3: "\u0190",
        4: "\u3123",
        5: "\u078e",
        6: "9",
        7: "\u3125",
        "&": "\u214b",
        ".": "\u02D9",
        '"': "\u201e",
        ";": "\u061b",
        "[": "]",
        "(": ")",
        "{": "}",
        "?": "\u00BF",
        "!": "\u00A1",
        "'": ",",
        "<": ">",
        "\u203E": "_",
        "\u00AF": "_",
        "\u203F": "\u2040",
        "\u2045": "\u2046",
        "\u2234": "\u2235",
        "\r": "\n",
        ß: "ᙠ",

        "\u0308": "\u0324",
        ä: "ɐ" + "\u0324",
        ö: "o" + "\u0324",
        ü: "n" + "\u0324",
        Ä: "\u2200" + "\u0324",
        Ö: "O" + "\u0324",
        Ü: "\u2229" + "\u0324",

        "´": " \u0317",
        é: "\u01DD" + "\u0317",
        á: "\u0250" + "\u0317",
        ó: "o" + "\u0317",
        ú: "n" + "\u0317",
        É: "\u018e" + "\u0317",
        Á: "\u2200" + "\u0317",
        Ó: "O" + "\u0317",
        Ú: "\u2229" + "\u0317",

        "`": " \u0316",
        è: "\u01DD" + "\u0316",
        à: "\u0250" + "\u0316",
        ò: "o" + "\u0316",
        ù: "n" + "\u0316",
        È: "\u018e" + "\u0316",
        À: "\u2200" + "\u0316",
        Ò: "O" + "\u0316",
        Ù: "\u2229" + "\u0316",

        "^": " \u032E",
        ê: "\u01DD" + "\u032e",
        â: "\u0250" + "\u032e",
        ô: "o" + "\u032e",
        û: "n" + "\u032e",
        Ê: "\u018e" + "\u032e",
        Â: "\u2200" + "\u032e",
        Ô: "O" + "\u032e",
        Û: "\u2229" + "\u032e",
        // TODO: flip more letters with stuff around them. See http://en.wikipedia.org/wiki/Combining_character
      },
    },

    // Mirror text (flip horizontally)
    mirror: {
      init: function () {
        // invert the map
        for (var i in this.map) {
          this.map[this.map[i]] = i;
        }
      },

      encode: function (text) {
        var ret = [],
          ch,
          newLines = [];

        for (var i = 0, len = text.length; i < len; i++) {
          ch = text.charAt(i);

          // combining diacritical marks: combine with previous character for ä,ö,ü,...
          if (
            i > 0 &&
            (ch == "\u0308" ||
              ch == "\u0300" ||
              ch == "\u0301" ||
              ch == "\u0302")
          ) {
            ch = this.map[text.charAt(i - 1) + ch];
            ret.pop();
          } else {
            ch = this.map[ch];
            if (typeof ch == "undefined") {
              ch = text.charAt(i);
            }
          }

          if (ch == "\n") {
            newLines.push(ret.reverse().join(""));
            ret = [];
          } else {
            ret.push(ch);
          }
        }
        newLines.push(ret.reverse().join(""));
        return newLines.join("\n");
      },

      decode: function (text) {
        var ret = [],
          ch,
          newLines = [];

        for (var i = 0, len = text.length; i < len; i++) {
          ch = text.charAt(i);

          // combining diacritical marks: combine with previous character for ä,ö,ü,...
          if (
            i > 0 &&
            (ch == "\u0308" ||
              ch == "\u0300" ||
              ch == "\u0301" ||
              ch == "\u0302")
          ) {
            ch = this.map[text.charAt(i - 1) + ch];
            ret.pop();
          } else {
            ch = this.map[ch];
            if (typeof ch == "undefined") {
              ch = text.charAt(i);
            }
          }

          if (ch == "\n") {
            newLines.push(ret.reverse().join(""));
            ret = [];
          } else {
            ret.push(ch);
          }
        }

        newLines.push(ret.reverse().join(""));
        return newLines.join("\n");
      },

      // Thanks to http://www.macchiato.com/unicode/mirrored-ascii
      map: {
        a: "ɒ",
        b: "d",
        c: "ɔ",
        e: "ɘ",
        f: "Ꮈ",
        g: "ǫ",
        h: "ʜ",
        j: "ꞁ",
        k: "ʞ",
        l: "|",
        n: "ᴎ",
        p: "q",
        r: "ɿ",
        s: "ꙅ",
        t: "ƚ",
        y: "ʏ",
        z: "ƹ",
        B: "ᙠ",
        C: "Ɔ",
        D: "ᗡ",
        E: "Ǝ",
        F: "ꟻ",
        G: "Ꭾ",
        J: "Ⴑ",
        K: "⋊",
        L: "⅃",
        N: "Ͷ",
        P: "ꟼ",
        Q: "Ọ",
        R: "Я",
        S: "Ꙅ",
        Z: "Ƹ",
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        "&": "",
        ";": "",
        "[": "]",
        "(": ")",
        "{": "}",
        "?": "⸮",
        "<": ">",

        ä: "ɒ" + "\u0308",
        ß: "ᙠ",

        "´": "`",
        é: "ɘ" + "\u0300",
        á: "ɒ" + "\u0300",
        ó: "ò",
        ú: "ù",
        É: "Ǝ" + "\u0300",
        Á: "À",
        Ó: "Ò",
        Ú: "Ù",

        "`": "´",
        è: "ɘ" + "\u0301",
        à: "ɒ" + "\u0301",
        È: "Ǝ" + "\u0301",

        ê: "ɘ" + "\u0302",
        â: "ɒ" + "\u0302",
        Ê: "Ǝ" + "\u0302",

        Ø: "ᴓ",
        ø: "ᴓ",
      },
    },

    // Thanks to Michael S. Kaplan: http://blogs.msdn.com/b/michkap/archive/2006/02/17/533929.aspx
    // Creepify.
    creepify: {
      init: function () {
        // Sort diacritics in top, bottom or middle

        for (var i = 768; i <= 789; i++) {
          this.diacriticsTop.push(String.fromCharCode(i));
        }

        for (var i = 790; i <= 819; i++) {
          if (i != 794 && i != 795) {
            this.diacriticsBottom.push(String.fromCharCode(i));
          }
        }
        this.diacriticsTop.push(String.fromCharCode(794));
        this.diacriticsTop.push(String.fromCharCode(795));

        for (var i = 820; i <= 824; i++) {
          this.diacriticsMiddle.push(String.fromCharCode(i));
        }

        for (var i = 825; i <= 828; i++) {
          this.diacriticsBottom.push(String.fromCharCode(i));
        }

        for (var i = 829; i <= 836; i++) {
          this.diacriticsTop.push(String.fromCharCode(i));
        }
        this.diacriticsTop.push(String.fromCharCode(836));
        this.diacriticsBottom.push(String.fromCharCode(837));
        this.diacriticsTop.push(String.fromCharCode(838));
        this.diacriticsBottom.push(String.fromCharCode(839));
        this.diacriticsBottom.push(String.fromCharCode(840));
        this.diacriticsBottom.push(String.fromCharCode(841));
        this.diacriticsTop.push(String.fromCharCode(842));
        this.diacriticsTop.push(String.fromCharCode(843));
        this.diacriticsTop.push(String.fromCharCode(844));
        this.diacriticsBottom.push(String.fromCharCode(845));
        this.diacriticsBottom.push(String.fromCharCode(846));
        // 847 (U+034F) is invisible http://en.wikipedia.org/wiki/Combining_grapheme_joiner
        this.diacriticsTop.push(String.fromCharCode(848));
        this.diacriticsTop.push(String.fromCharCode(849));
        this.diacriticsTop.push(String.fromCharCode(850));
        this.diacriticsBottom.push(String.fromCharCode(851));
        this.diacriticsBottom.push(String.fromCharCode(852));
        this.diacriticsBottom.push(String.fromCharCode(853));
        this.diacriticsBottom.push(String.fromCharCode(854));
        this.diacriticsTop.push(String.fromCharCode(855));
        this.diacriticsTop.push(String.fromCharCode(856));
        this.diacriticsBottom.push(String.fromCharCode(857));
        this.diacriticsBottom.push(String.fromCharCode(858));
        this.diacriticsTop.push(String.fromCharCode(859));
        this.diacriticsBottom.push(String.fromCharCode(860));
        this.diacriticsTop.push(String.fromCharCode(861));
        this.diacriticsTop.push(String.fromCharCode(861));
        this.diacriticsBottom.push(String.fromCharCode(863));
        this.diacriticsTop.push(String.fromCharCode(864));
        this.diacriticsTop.push(String.fromCharCode(865));
      },

      encode: function (text) {
        var newText = "",
          newChar;
        for (var i in text) {
          newChar = text[i];

          // Middle
          // Put just one of the middle characters there, or it gets crowded
          if (this.options.middle) {
            newChar +=
              this.diacriticsMiddle[
                Math.floor(Math.random() * this.diacriticsMiddle.length)
              ];
          }

          // Top
          if (this.options.top) {
            // Put up to this.options.maxHeight random diacritics on top.
            // optionally fluctuate the number via the randomization value (0-100%)
            // randomization 100%: 0 to maxHeight
            //                30%: 70% of maxHeight to maxHeight
            //                 x%: 100-x% of maxHeight to maxHeight
            var diacriticsTopLength = this.diacriticsTop.length - 1;
            for (
              var count = 0,
                len =
                  this.options.maxHeight -
                  Math.random() *
                    ((this.options.randomization / 100) *
                      this.options.maxHeight);
              count < len;
              count++
            ) {
              newChar +=
                this.diacriticsTop[
                  Math.floor(Math.random() * diacriticsTopLength)
                ];
            }
          }

          // Bottom
          if (this.options.bottom) {
            var diacriticsBottomLength = this.diacriticsBottom.length - 1;
            for (
              var count = 0,
                len =
                  this.options.maxHeight -
                  Math.random() *
                    ((this.options.randomization / 100) *
                      this.options.maxHeight);
              count < len;
              count++
            ) {
              newChar +=
                this.diacriticsBottom[
                  Math.floor(Math.random() * diacriticsBottomLength)
                ];
            }
          }

          newText += newChar;
        }
        return newText;
      },

      decode: function (text) {
        var newText = "",
          charCode;

        for (var i in text) {
          charCode = text[i].charCodeAt(0);
          if (charCode < 768 || charCode > 865) {
            newText += text[i];
          }
        }
        return newText;
      },

      diacriticsTop: [],
      diacriticsMiddle: [],
      diacriticsBottom: [],

      options: {
        top: true,
        middle: true,
        bottom: true,
        maxHeight: 15, // How many diacritic marks shall we put on top/bottom?
        randomization: 100, // 0-100%. maxHeight 100 and randomization 20%: the height goes from 80 to 100. randomization 70%, height goes from 30 to 100.
      },
    },

    // Circles around Letters. Uses special circle characters for some letters and combining characters for the rest
    // Thanks to
    // - Alan Wood: http://www.alanwood.net/unicode/enclosed_alphanumerics.html
    bubbles: {
      init: function () {
        // Numbers
        for (var i = 49; i <= 57; i++) {
          this.map[String.fromCharCode(i)] = String.fromCharCode(i + 9263);
        }
        this.map["0"] = "\u24ea";

        // Capital letters
        for (var i = 65; i <= 90; i++) {
          this.map[String.fromCharCode(i)] = String.fromCharCode(i + 9333);
        }

        // Lower letters
        for (var i = 97; i <= 122; i++) {
          this.map[String.fromCharCode(i)] = String.fromCharCode(i + 9327);
        }

        // invert the map
        for (var i in this.map) {
          this.mapInverse[this.map[i]] = i;
        }
      },

      encode: function (text) {
        var ret = "",
          ch,
          first = true;

        for (var i in text) {
          ch = this.map[text[i]];

          // No dedicated circled character available? Use a Combining Diacritical Mark surrounded
          // with non-breaking spaces, so it doesn't overlap
          if (typeof ch == "undefined") {
            if (text[i].charCodeAt(0) >= 33) {
              ch = text[i] + String.fromCharCode(8413);
              if (!first) {
                ch =
                  String.fromCharCode(8239) +
                  String.fromCharCode(160) +
                  String.fromCharCode(160) +
                  String.fromCharCode(8239) +
                  ch;
              }
            } else {
              ch = text[i];
            }
          }
          ret += ch;
          first = ch == "\n";
        }
        return ret;
      },

      decode: function (text) {
        var ret = "",
          ch,
          newRet = "";

        for (var i in text) {
          ch = this.mapInverse[text[i]];
          ret += typeof ch == "undefined" ? text[i] : ch;
        }

        for (var i in ret) {
          ch = ret[i].charCodeAt(0);
          if (ch != 160 && ch != 8239 && ch != 8413) {
            newRet += ret[i];
          }
        }

        return newRet;
      },

      map: {},
      mapInverse: {},
    },

    // Puts a Square Combining Character after a letter, thus ensquaring it, squarily.
    squares: {
      init: function () {},

      encode: function (text) {
        var ret = "",
          ch,
          first = true;

        for (var i in text) {
          if (text[i].charCodeAt(0) >= 33) {
            ch = text[i] + String.fromCharCode(8414);
            if (!first) {
              ch =
                String.fromCharCode(8239) +
                String.fromCharCode(160) +
                String.fromCharCode(160) +
                String.fromCharCode(8239) +
                ch;
            }
          } else {
            ch = text[i];
          }

          ret += ch;
          first = ch == "\n";
        }
        return ret;
      },

      decode: function (text) {
        var ret = "",
          ch;

        for (var i in text) {
          ch = text[i].charCodeAt(0);
          if (ch != 160 && ch != 8239 && ch != 8414) {
            ret += text[i];
          }
        }

        return ret;
      },
    },

    // Same as squares, just round.
    roundsquares: {
      init: function () {},

      encode: function (text) {
        var ret = "",
          ch,
          first = true;

        for (var i in text) {
          if (text[i].charCodeAt(0) >= 33) {
            ch = text[i] + String.fromCharCode(8419);
            if (!first) {
              ch =
                String.fromCharCode(160) +
                String.fromCharCode(160) +
                String.fromCharCode(160) +
                ch;
            }
          } else {
            ch = text[i];
          }

          ret += ch;
          first = ch == "\n";
        }
        return ret;
      },

      decode: function (text) {
        var ret = "",
          ch;

        for (var i in text) {
          ch = text[i].charCodeAt(0);
          if (ch != 160 && ch != 8239 && ch != 8419) {
            ret += text[i];
          }
        }

        return ret;
      },
    },

    // Weird looking alternatives to most characters
    bent: {
      init: function () {
        // invert the map
        for (var i in this.map) {
          this.map[this.map[i]] = i;
        }
      },

      encode: function (text) {
        var ret = "",
          ch;

        for (var i = 0, len = text.length; i < len; i++) {
          ch = this.map[text.charAt(i)];
          if (typeof ch == "undefined") {
            ch = text.charAt(i);
          }
          ret += ch;
        }

        return ret;
      },

      decode: function (text) {
        var ret = "",
          ch;

        for (var i = 0, len = text.length; i < len; i++) {
          ch = this.map[text.charAt(i)];
          if (typeof ch == "undefined") {
            ch = text.charAt(i);
          }
          ret += ch;
        }
        return ret;
      },

      // Thanks to Eddie Ringle for most lowercase letters: http://funicode.com
      map: {
        a: "ą",
        b: "ҍ",
        c: "ç",
        d: "ժ",
        e: "ҽ",
        f: "ƒ",
        g: "ց",
        h: "հ",
        i: "ì",
        j: "ʝ",
        k: "ҟ",
        l: "Ӏ",
        m: "ʍ",
        n: "ղ",
        o: "օ",
        p: "ք",
        q: "զ",
        r: "ɾ",
        s: "ʂ",
        t: "է",
        u: "մ",
        v: "ѵ",
        w: "ա",
        x: "×",
        y: "վ",
        z: "Հ",
        A: "Ⱥ",
        B: "β",
        C: "↻",
        D: "Ꭰ",
        E: "Ɛ",
        F: "Ƒ",
        G: "Ɠ",
        H: "Ƕ",
        I: "į",
        J: "ل",
        K: "Ҡ",
        L: "Ꝉ",
        M: "Ɱ",
        N: "ហ",
        O: "ට",
        P: "φ",
        Q: "Ҩ",
        R: "འ",
        S: "Ϛ",
        T: "Ͳ",
        U: "Ա",
        V: "Ỽ",
        W: "చ",
        X: "ჯ",
        Y: "Ӌ",
        Z: "ɀ",
        0: "⊘",
        1: "������",
        2: "ϩ",
        3: "Ӡ",
        4: "५",
        5: "Ƽ",
        6: "Ϭ",
        7: "7",
        8: "������",
        9: "९",
        "&": "⅋",
        "(": "{",
        ")": "}",
        "{": "(",
        "}": ")",

        ä: "ą" + "\u0308",
        ö: "օ" + "\u0308",
        ü: "մ" + "\u0308",
        Ä: "Ⱥ" + "\u0308",
        Ö: "ට" + "\u0308",
        Ü: "Ա" + "\u0308",

        é: "ҽ" + "\u0301",
        á: "ą" + "\u0301",
        ó: "օ" + "\u0301",
        ú: "մ" + "\u0301",
        É: "Ɛ" + "\u0301",
        Á: "Ⱥ" + "\u0301",
        Ó: "ට" + "\u0301",
        Ú: "Ա" + "\u0301",

        è: "ҽ" + "\u0300",
        à: "ą" + "\u0300",
        ò: "օ" + "\u0300",
        ù: "մ" + "\u0300",
        È: "Ɛ" + "\u0300",
        À: "Ⱥ" + "\u0300",
        Ò: "ට" + "\u0300",
        Ù: "Ա" + "\u0300",

        ê: "ҽ" + "\u0302",
        â: "ą" + "\u0302",
        ô: "օ" + "\u0302",
        û: "մ" + "\u0302",
        Ê: "Ɛ" + "\u0302",
        Â: "Ⱥ" + "\u0302",
        Ô: "ට" + "\u0302",
        Û: "Ա" + "\u0302",
      },
    },

    // Tiny Capitals
    tiny: {
      init: function () {
        // invert the map
        for (var i in this.map) {
          this.map[this.map[i]] = i;
        }
      },

      encode: function (text) {
        var ret = "",
          ch;
        text = text.toUpperCase();
        for (var i = 0, len = text.length; i < len; i++) {
          ch = this.map[text.charAt(i)];
          if (typeof ch == "undefined") {
            ch = text.charAt(i);
          }
          ret += ch;
        }

        return ret;
      },

      decode: function (text) {
        var ret = "",
          ch;

        for (var i = 0, len = text.length; i < len; i++) {
          ch = this.map[text.charAt(i)];
          if (typeof ch == "undefined") {
            ch = text.charAt(i);
          }
          ret += ch;
        }
        return ret;
      },

      // TODO: Find small lower case letters
      map: {
        A: "ᴀ",
        B: "ʙ",
        C: "ᴄ",
        D: "ᴅ",
        E: "ᴇ",
        F: "ꜰ",
        G: "ɢ",
        H: "ʜ",
        I: "ɪ",
        J: "ᴊ",
        K: "ᴋ",
        L: "ʟ",
        M: "ᴍ",
        N: "ɴ",
        O: "ᴏ",
        P: "ᴘ",
        Q: "Q",
        R: "ʀ",
        S: "ꜱ",
        T: "ᴛ",
        U: "ᴜ",
        V: "ᴠ",
        W: "ᴡ",
        X: "x",
        Y: "ʏ",
        Z: "ᴢ",
      },
    },
  };

  ////// functions

  // init
  for (var i in this.tools) {
    this.tools[i].init();
  }

  // Encode every character: U+00A0 -> &#x00a0; etc.
  this.getHTML = function (text) {
    var html = "",
      ch,
      lastSpaceWasNonBreaking = true, // for alternating [non-braking] spaces
      highSurrogate = 0,
      codepoint = 0;

    for (var i = 0, len = text.length; i < len; i++) {
      ch = text.charCodeAt(i);

      // line break: add <br>\n
      if (ch == 10 || ch == 13) {
        html += "<br>\n";
        lastSpaceWasNonBreaking = true;

        // space: add alternating space and non-breaking space (U+00A0). Otherwise
        // a series of normal spaces       would collapse to one in the browser
      } else if (ch == 32) {
        if (lastSpaceWasNonBreaking) {
          html += " ";
          lastSpaceWasNonBreaking = false;
        } else {
          html += "&nbsp;";
          lastSpaceWasNonBreaking = true;
        }

        // Normal character: Decode. Special cases for higher numbers:
        // http://en.wikipedia.org/wiki/Mapping_of_Unicode_characters#Surrogates
      } else {
        // Character is high surrogate: Remember and continue
        if (ch >= 0xd800 && ch <= 0xdbff) {
          highSurrogate = ch;
          codepoint = 0;

          // last character was high surrogate: Combine with low surrogate
        } else if (highSurrogate > 0) {
          // If char is low surrogate:
          if (ch >= 0xdc00 && ch <= 0xdfff) {
            codepoint =
              (highSurrogate - 0xd800) * 1024 + (ch - 0xdc00) + 0x10000;
          }
          highSurrogate = 0;

          // no surrogates: Just take the character
        } else {
          codepoint = ch;
        }

        if (codepoint != 0) {
          html += "&#x" + codepoint.toString(16) + ";";
          lastSpaceWasNonBreaking = true;
        }
      }
    }

    return html;
  };
}
export var luni = new Lunicode();
luni.tools.creepify.options.maxHeight = 10;

export const createMap = (chars) => {
  var alphanum = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];
  var i = 0;
  var map = {};
  for (var c of chars) {
    map[alphanum[i]] = c;
    i++;
  }
  return JSON.stringify(map);
};
//createMap("𝕒𝕓𝕔𝕕𝕖𝕗𝕘𝕙𝕚𝕛𝕜𝕝𝕞𝕟𝕠𝕡𝕢𝕣𝕤𝕥𝕦𝕧𝕨𝕩𝕪𝕫𝔸𝔹ℂ𝔻𝔼𝔽𝔾ℍ𝕀𝕁𝕂𝕃𝕄ℕ𝕆ℙℚℝ𝕊𝕋𝕌𝕍𝕎𝕏𝕐ℤ𝟘𝟙𝟚𝟛𝟜𝟝𝟞𝟟𝟠𝟡")

export const forward_unicode_font = (text) => {
  text = text.trim();
  if (text === "") {
    return "";
  }
  var output = [];
  output.push(applyCharMap(oldEnglishCharMap, text) + "\n\n");
  output.push(applyCharMap(medievalCharMap, text) + "\n\n");
  output.push(fullCrazy(text) + "\n\n");
  output.push(applyCharMap(cursiveCharMap, text) + "\n\n");
  output.push(scriptify(text) + "\n\n");
  output.push(applyCharMap(doubleStruckCharMap, text) + "\n\n");
  output.push(applyCharMap(wideTextCharMap, text) + "\n\n");
  output.push(applyCharMap(taiVietCharMap, text) + "\n\n");
  output.push(cuteText(text) + "\n\n");
  output.push(luni.tools.tiny.encode(text) + "\n\n");
  output.push(luni.tools.flip.encode(text) + "\n\n");
  output.push(luni.tools.roundsquares.encode(text) + "\n\n");
  output.push(luni.tools.squares.encode(text) + "\n\n");
  output.push(applyCharMap(squaresCharMap, text) + "\n\n");
  output.push(luni.tools.mirror.encode(text) + "\n\n");
  output.push(luni.tools.creepify.encode(text) + "\n\n");
  output.push(applyCharMap(invertedSquaresCharMap, text) + "\n\n");
  output.push(applyCharMap(subscriptCharMap, text) + "\n\n");
  output.push(applyCharMap(superscriptCharMap, text) + "\n\n");
  output.push(luni.tools.bubbles.encode(text) + "\n\n");
  output.push(applyCharMap(squiggleCharMap, text) + "\n\n");
  output.push(applyCharMap(squiggle2CharMap, text) + "\n\n");
  output.push(applyCharMap(squiggle3CharMap, text) + "\n\n");
  output.push(applyCharMap(squiggle4CharMap, text) + "\n\n");
  output.push(applyCharMap(squiggle5CharMap, text) + "\n\n");
  output.push(applyCharMap(squiggle6CharMap, text) + "\n\n");
  output.push(applyCharMap(boldCharMap, text) + "\n\n");
  output.push(applyCharMap(boldSansCharMap, text) + "\n\n");
  output.push(applyCharMap(italicCharMap, text) + "\n\n");
  output.push(applyCharMap(boldItalicCharMap, text) + "\n\n");
  output.push(applyCharMap(monospaceCharMap, text) + "\n\n");
  output.push(applyCharMap(upperAnglesCharMap, text) + "\n\n");
  output.push(applyCharMap(greekCharMap, text) + "\n\n");
  output.push(applyCharMap(symbolsCharMap, text) + "\n\n");
  output.push(applyCharMap(currencyCharMap, text) + "\n\n");
  output.push(applyCharMap(asianStyleCharMap, text) + "\n\n");
  output.push(applyCharMap(asianStyle2CharMap, text) + "\n\n");
  output.push(thickBlockFramed(text) + "\n\n");
  output.push(diametricAngleFrame(text) + "\n\n");
  output.push(wavyJoiner(text) + "\n\n");
  output.push(dottyJoiner(text) + "\n\n");
  output.push(kirbyHug(text) + "\n\n");
  output.push(vaporwaveText(text) + "\n\n");
  output.push(littleSparkles(text) + "\n\n");
  output.push(weirdBox(text) + "\n\n");
  output.push(firework(text) + "\n\n");
  output.push(applyCharMap(bentTextCharMap, text) + "\n\n");
  output.push(applyCharMap(neonCharMap, text) + "\n\n");
  output.push(applyCharMap(futureAlienCharMap, text) + "\n\n");
  output.push(strikeThrough(text) + "\n\n");
  output.push(tildeStrikeThrough(text) + "\n\n");
  output.push(slashThrough(text) + "\n\n");
  output.push(underline(text) + "\n\n");
  output.push(doubleUnderline(text) + "\n\n");
  output.push(stinky(text) + "\n\n");
  output.push(heartsBetween(text) + "\n\n");
  output.push(arrowBelow(text) + "\n\n");
  output.push(crossAboveBelow(text) + "\n\n");
  output.push("Wingdings: " + wingdings(text) + "\n\n");
  output.push(cuteText(text) + "\n\n");
  output.push(cuteText(text) + "\n\n");
  output.push(cuteText(text) + "\n\n");
  output.push(cuteText(text) + "\n\n");
  output.push(cuteText(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(crazyWithFlourishOrSymbols(text) + "\n\n");
  output.push(cuteText(text) + "\n\n");
  output.push(cuteText(text) + "\n\n");
  output.push(cuteText(text) + "\n\n");

  return output;
};

export const crazyWithFlourishOrSymbols = (text) => {
  if (Math.random() < 0.7) return wrapInSymbols(crazifyText(text), 2);
  else return wrapInFlourish(crazifyText(text), 2);
};

export const strikeThrough = (text) => {
  return text.split("").join("̶") + "̶";
};
export const tildeStrikeThrough = (text) => {
  return text.split("").join("̴") + "̴";
};
export const underline = (text) => {
  return text.split("").join("̲") + "̲";
};
export const doubleUnderline = (text) => {
  return text.split("").join("̳") + "̳";
};
export const slashThrough = (text) => {
  return text.split("").join("̷") + "̷";
};
export const stinky = (text) => {
  return text.split("").join("̾") + "̾";
};
export const heartsBetween = (text) => {
  return text.split("").join("♥");
};
export const arrowBelow = (text) => {
  return text.split("").join("͎") + "͎";
};
export const crossAboveBelow = (text) => {
  return text.split("").join("͓̽") + "͓̽";
};

const wingdingsCharMap = {
  0: "📁︎",
  1: "📂︎",
  2: "📄︎",
  3: "🗏︎",
  4: "🗐︎",
  5: "🗄︎",
  6: "⌛︎",
  7: "🖮︎",
  8: "🖰︎",
  9: "🖲︎",
  "!": "✏︎",
  '"': "✂︎",
  "#": "✁︎",
  $: "👓︎",
  "%": "🕭︎",
  "&": "🕮︎",
  "'": "🕯︎",
  "(": "🕿︎",
  ")": "✆︎",
  "*": "🖂︎",
  "+": "🖃︎",
  ",": "📪︎",
  "-": "📫︎",
  ".": "📬︎",
  "/": "📭︎",
  ":": "🖳︎",
  ";": "🖴︎",
  "<": "🖫︎",
  "=": "🖬︎",
  ">": "✇︎",
  "?": "✍︎",
  A: "✌︎",
  B: "👌︎",
  C: "👍︎",
  D: "👎︎",
  E: "☜︎",
  F: "☞︎",
  G: "☝︎",
  H: "☟︎",
  I: "✋︎",
  J: "☺︎",
  K: "😐︎",
  L: "☹︎",
  M: "💣︎",
  N: "☠︎",
  O: "⚐︎",
  P: "🏱︎",
  Q: "✈︎",
  R: "☼︎",
  S: "💧︎",
  T: "❄︎",
  U: "🕆︎",
  V: "✞︎",
  W: "🕈︎",
  X: "✠︎",
  Y: "✡︎",
  Z: "☪︎",
  "[": "☯︎",
  "\\": "ॐ︎",
  "]": "☸︎",
  "^": "♈︎",
  _: "♉︎",
  "`": "♊︎",
  a: "♋︎",
  b: "♌︎",
  c: "♍︎",
  d: "♎︎",
  e: "♏︎",
  f: "♐︎",
  g: "♑︎",
  h: "♒︎",
  i: "♓︎",
  j: "🙰",
  k: "🙵",
  l: "●︎",
  m: "❍︎",
  n: "■︎",
  o: "□︎",
  p: "◻︎",
  q: "❑︎",
  r: "❒︎",
  s: "⬧︎",
  t: "⧫︎",
  u: "◆︎",
  v: "❖︎",
  w: "⬥︎",
  x: "⌧︎",
  y: "⍓︎",
  z: "⌘︎",
  "{": "❀︎",
  "|": "✿︎",
  "}": "❝︎",
  "~": "❞︎",
  "": "▯︎",
  "€": "⓪︎",
  "": "①︎",
  "‚": "②︎",
  ƒ: "③︎",
  "„": "④︎",
  "…": "⑤︎",
  "†": "⑥︎",
  "‡": "⑦︎",
  ˆ: "⑧︎",
  "‰": "⑨︎",
  Š: "⑩︎",
  "‹": "⓿︎",
  Œ: "❶︎",
  "": "❷︎",
  Ž: "❸︎",
  "": "❹︎",
  "": "❺︎",
  "‘": "❻︎",
  "’": "❼︎",
  "“": "❽︎",
  "”": "❾︎",
  "•": "❿︎",
  "–": "◻︎",
  "—": "◻︎",
  "˜": "◻︎",
  "™": "◻︎",
  š: "◻︎",
  "›": "◻︎",
  œ: "◻︎",
  "": "◻︎",
  ž: "·︎",
  Ÿ: "•︎",
  "¡": "○︎",
  "¢": "⭕︎",
  "£": "◻︎",
  "¤": "◉︎",
  "¥": "◎︎",
  "¦": "◻︎",
  "§": "▪︎",
  "¨": "◻︎",
  "©": "◻︎",
  ª: "✦︎",
  "«": "★︎",
  "¬": "✶︎",
  "®": "✹︎",
  "¯": "✵︎",
  "°": "◻︎",
  "±": "⌖︎",
  "²": "⟡︎",
  "³": "⌑︎",
  "´": "◻︎",
  µ: "✪︎",
  "¶": "✰︎",
  "·": "🕐︎",
  "¸": "🕑︎",
  "¹": "🕒︎",
  º: "🕓︎",
  "»": "🕔︎",
  "¼": "🕕︎",
  "½": "🕖︎",
  "¾": "🕗︎",
  "¿": "🕘︎",
  À: "🕙︎",
  Á: "🕚︎",
  Â: "🕛︎",
  Ã: "◻︎",
  Ä: "◻︎",
  Å: "◻︎",
  Æ: "◻︎",
  Ç: "◻︎",
  È: "◻︎",
  É: "◻︎",
  Ê: "◻︎",
  Ë: "◻︎",
  Ì: "◻︎",
  Í: "◻︎",
  Î: "◻︎",
  Ï: "◻︎",
  Ð: "◻︎",
  Ñ: "◻︎",
  Ò: "◻︎",
  Ó: "◻︎",
  Ô: "◻︎",
  Õ: "⌫︎",
  Ö: "⌦︎",
  "×": "◻︎",
  Ø: "➢︎",
  Ù: "◻︎",
  Ú: "◻︎",
  Û: "◻︎",
  Ü: "➲︎",
  Ý: "◻︎",
  Þ: "◻︎",
  ß: "◻︎",
  à: "◻︎",
  á: "◻︎",
  â: "◻︎",
  ã: "◻︎",
  ä: "◻︎",
  å: "◻︎",
  æ: "◻︎",
  ç: "◻︎",
  è: "➔︎",
  é: "◻︎",
  ê: "◻︎",
  ë: "◻︎",
  ì: "◻︎",
  í: "◻︎",
  î: "◻︎",
  ï: "⇦︎",
  ð: "⇨︎",
  ñ: "⇧︎",
  ò: "⇩︎",
  ó: "⬄︎",
  ô: "⇳︎",
  õ: "⬀︎",
  ö: "⬁︎",
  "÷": "⬃︎",
  ø: "⬂︎",
  ù: "▭︎",
  ú: "▫︎",
  û: "✗︎",
  ü: "✓︎",
  ý: "☒︎",
  þ: "☑︎",
  ÿ: "◻︎",
};
export const wingdings = (text) => {
  return text
    .split("")
    .map(function (a) {
      return wingdingsCharMap[a] ? wingdingsCharMap[a] : a;
    })
    .join("");
};

const vaporwaveCharMap = {
  " ": "　",
  "`": "`",
  1: "１",
  2: "２",
  3: "３",
  4: "４",
  5: "５",
  6: "６",
  7: "７",
  8: "８",
  9: "９",
  0: "０",
  "-": "－",
  "=": "＝",
  "~": "~",
  "!": "！",
  "@": "＠",
  "#": "＃",
  $: "＄",
  "%": "％",
  "^": "^",
  "&": "＆",
  "*": "＊",
  "(": "（",
  ")": "）",
  _: "_",
  "+": "＋",
  q: "ｑ",
  w: "ｗ",
  e: "ｅ",
  r: "ｒ",
  t: "ｔ",
  y: "ｙ",
  u: "ｕ",
  i: "ｉ",
  o: "ｏ",
  p: "ｐ",
  "[": "[",
  "]": "]",
  "\\": "\\",
  Q: "Ｑ",
  W: "Ｗ",
  E: "Ｅ",
  R: "Ｒ",
  T: "Ｔ",
  Y: "Ｙ",
  U: "Ｕ",
  I: "Ｉ",
  O: "Ｏ",
  P: "Ｐ",
  "{": "{",
  "}": "}",
  "|": "|",
  a: "ａ",
  s: "ｓ",
  d: "ｄ",
  f: "ｆ",
  g: "ｇ",
  h: "ｈ",
  j: "ｊ",
  k: "ｋ",
  l: "ｌ",
  ";": "；",
  "'": "＇",
  A: "Ａ",
  S: "Ｓ",
  D: "Ｄ",
  F: "Ｆ",
  G: "Ｇ",
  H: "Ｈ",
  J: "Ｊ",
  K: "Ｋ",
  L: "Ｌ",
  ":": "：",
  '"': '"',
  z: "ｚ",
  x: "ｘ",
  c: "ｃ",
  v: "ｖ",
  b: "ｂ",
  n: "ｎ",
  m: "ｍ",
  ",": "，",
  ".": "．",
  "/": "／",
  Z: "Ｚ",
  X: "Ｘ",
  C: "Ｃ",
  V: "Ｖ",
  B: "Ｂ",
  N: "Ｎ",
  M: "Ｍ",
  "<": "<",
  ">": ">",
  "?": "？",
};
export const vaporwaveText = (text) => {
  var numSpaces = text.split(" ").length;
  text = applyCharMap(vaporwaveCharMap, text);
  var asianChars = getAsianChars(Math.max(3, numSpaces));
  if (numSpaces > 6)
    asianChars = asianChars
      .split("")
      .map((c) => c + ["", "　"][Math.round(Math.random() * 0.6)])
      .join("");
  var outputs = [];
  outputs.push(text + "　" + asianChars);
  outputs.push(
    text
      .replace(/　/g, "░")
      .replace(/ａｅ/, "æ")
      .replace(/Ａ/g, "Λ")
      .replace(/Ｅ/g, function () {
        return Math.random() > 0.5 ? "Ξ" : "Σ";
      })
      .replace(/Ｏ/g, "♢") +
      "　（" +
      asianChars +
      "）"
  );
  outputs.push("【﻿" + text + "】");
  return outputs.join("\n\n");
};

export const getAsianChars = (n) => {
  if (!n) n = 1;
  var chars =
    "リサフランク現代のコンピュ竹内 まりや若者が履く流行のスニーカー真夜中のドアホットドッグマスターストライカーソニーブギ新しい日の誕生ライフ - ヒスイ蒸気波 無線゠ァアィイゥウェエォオカガキギクグケゲコゴサザシジスズセゼソゾタダチヂッツヅテデトドナニヌネノハバパヒビピフブプヘベペホボポマミムメモャヤュユョヨラリルレロヮワヰヱヲンヴヵヶヷヸヹヺ・ーヽヾヿぁあぃいぅうぇえぉおかがきぎくぐけげこごさざしじすずせぜそぞただちぢっつづてでとどなにぬねのはばぱひびぴふぶぷへべぺほぼぽまみむめもゃやゅゆょよらりるれろゎわゐゑをんゔゕゖ゙゚゛゜ゝゞゟ亜哀挨愛曖悪握圧扱宛嵐安案暗以衣位囲医依委威為畏胃尉異移萎偉椅彙意違維慰遺緯域育壱逸茨芋引印因咽姻員院淫陰飲隠韻右宇羽雨唄鬱畝浦運雲永泳英映栄営詠影鋭衛易疫益液駅悦越謁閲円延沿炎怨宴媛援園煙猿遠鉛塩演縁艶汚王凹央応往押旺欧殴桜翁奥横岡屋億憶臆虞乙俺卸音恩温穏下化火加可仮何花佳価果河苛科";
  var str = "";
  for (var i = 0; i < n; i++) {
    str += chars[Math.floor(Math.random() * chars.length)];
  }
  return str;
};

const flourishArray = [
  "★·.·´¯`·.·★ [[text]] ★·.·´¯`·.·★",
  "▁ ▂ ▄ ▅ ▆ ▇ █ [[text]] █ ▇ ▆ ▅ ▄ ▂ ▁",
  "°°°·.°·..·°¯°·._.· [[text]] ·._.·°¯°·.·° .·°°°",
  "¸,ø¤º°`°º¤ø,¸¸,ø¤º° [[text]] °º¤ø,¸¸,ø¤º°`°º¤ø,¸",
  "ıllıllı [[text]] ıllıllı",
  "•?((¯°·._.• [[text]] •._.·°¯))؟•",
  "▌│█║▌║▌║ [[text]] ║▌║▌║█│▌",
  "×º°”˜`”°º× [[text]] ×º°”˜`”°º×",
  "•]••´º´•» [[text]] «•´º´••[•",
  "*•.¸♡ [[text]] ♡¸.•*",
  "╰☆☆ [[text]] ☆☆╮",
  ".•°¤*(¯`★´¯)*¤° [[text]] °¤*(¯´★`¯)*¤°•.",
  "(¯´•._.• [[text]] •._.•´¯)",
  "¸„.-•~¹°”ˆ˜¨ [[text]] ¨˜ˆ”°¹~•-.„¸",
  "░▒▓█ [[text]] █▓▒░",
  "░▒▓█►─═  [[text]] ═─◄█▓▒░",
  "★彡 [[text]] 彡★",
  "•´¯`•. [[text]] .•´¯`•",
  "§.•´¨'°÷•..× [[text]] ×,.•´¨'°÷•..§",
  "•°¯`•• [[text]] ••´¯°•",
  "(¯`*•.¸,¤°´✿.｡.:* [[text]] *.:｡.✿`°¤,¸.•*´¯)",
  "|!¤*'~``~'*¤!| [[text]] |!¤*'~``~'*¤!|",
  "•._.••´¯``•.¸¸.•` [[text]] `•.¸¸.•´´¯`••._.•",
  "¸„.-•~¹°”ˆ˜¨ [[text]] ¨˜ˆ”°¹~•-.„¸",
  "(¯´•._.• [[text]] •._.•´¯)",
  "••¤(`×[¤ [[text]] ¤]×´)¤••",
  "•´¯`•» [[text]] «•´¯`•",
  " .o0×X×0o. [[text]] .o0×X×0o.",
  "¤¸¸.•´¯`•¸¸.•..>> [[text]] <<..•.¸¸•´¯`•.¸¸¤",
  "—(••÷[ [[text]] ]÷••)—",
  "¸,ø¤º°`°º¤ø,¸ [[text]] ¸,ø¤º°`°º¤ø,¸",
  "`•.¸¸.•´´¯`••._.• [[text]] •._.••`¯´´•.¸¸.•`",
  ",-*' ^ '~*-.,_,.-*~ [[text]] ~*-.,_,.-*~' ^ '*-,",
  "`•.,¸¸,.•´¯ [[text]] ¯`•.,¸¸,.•´",
  "↤↤↤↤↤ [[text]] ↦↦↦↦↦",
  "➶➶➶➶➶ [[text]] ➷➷➷➷➷",
  "↫↫↫↫↫ [[text]] ↬↬↬↬↬",
  "·.¸¸.·♩♪♫ [[text]] ♫♪♩·.¸¸.·",
  "【｡_｡】 [[text]] 【｡_｡】",
  "]|I{•------» [[text]] «------•}I|[",
  "▀▄▀▄▀▄ [[text]] ▄▀▄▀▄▀",
  "(-_-) [[text]] (-_-)",
  "•´¯`•. [[text]] .•´¯`•",
  "-漫~*'¨¯¨'*·舞~ [[text]] ~舞*'¨¯¨'*·~漫-",
  "๑۞๑,¸¸,ø¤º°`°๑۩ [[text]] ๑۩ ,¸¸,ø¤º°`°๑۞๑",
  ".•°¤*(¯`★´¯)*¤° [[text]] °¤*(¯´★`¯)*¤°•.",
  "••.•´¯`•.•• [[text]] ••.•´¯`•.••",
  "¤¸¸.•´¯`•¸¸.•..>> [[text]] <<..•.¸¸•´¯`•.¸¸¤",
  "◦•●◉✿ [[text]] ✿◉●•◦",
  "╚»★«╝ [[text]] ╚»★«╝",
  "-·=»‡«=·- [[text]] -·=»‡«=·-",
  "∙∙·▫▫ᵒᴼᵒ▫ₒₒ▫ᵒᴼᵒ▫ₒₒ▫ᵒᴼᵒ [[text]] ᵒᴼᵒ▫ₒₒ▫ᵒᴼᵒ▫ₒₒ▫ᵒᴼᵒ▫▫·∙∙",
  "¸¸♬·¯·♩¸¸♪·¯·♫¸¸ [[text]] ¸¸♫·¯·♪¸¸♩·¯·♬¸¸",
  "ஜ۩۞۩ஜ [[text]] ஜ۩۞۩ஜ",
  "¤ (¯´☆✭.¸_)¤ [[text]] ¤(_¸.✭☆´¯) ¤",
  "(¯`·.¸¸.·´¯`·.¸¸.-> [[text]] <-.¸¸.·´¯`·.¸¸.·´¯)",
  "✿.｡.:* ☆:**:. [[text]] .:**:.☆*.:｡.✿",
  ".•♫•♬• [[text]] •♬•♫•.",
  "ღ(¯`◕‿◕´¯) ♫ ♪ ♫ [[text]] ♫ ♪ ♫ (¯`◕‿◕´¯)ღ",
  "«-(¯`v´¯)-« [[text]] »-(¯`v´¯)-»",
];
export const wrapInFlourish = (text) => {
  return flourishArray[
    Math.floor(Math.random() * flourishArray.length)
  ].replace("[[text]]", text);
};

export const wrapInSymbols = (text, number) => {
  return randomSymbols(number) + "  " + text + "  " + randomSymbols(number);
};

export const firework = (text) => {
  return text.split("").join("҉") + "҉";
};

export const weirdBox = (text) => {
  return text.replace(/([^\s])/g, "[̲̅$1]");
};

export const littleSparkles = (text) => {
  return "˜”*°•.˜”*°• " + text + " •°*”˜.•°*”˜";
};

export const kirbyHug = (text) => {
  return "(っ◔◡◔)っ ♥ " + text + " ♥";
};

export const dottyJoiner = (text) => {
  return "░" + text.split("").join("░") + "░";
};

export const wavyJoiner = (text) => {
  return "≋" + text.split("").join("≋") + "≋";
};

export const diametricAngleFrame = (text) => {
  return text.replace(/([^\s])/g, "『$1』");
};

export const thickBlockFramed = (text) => {
  return text.replace(/([^\s])/g, "【$1】");
};

export const applyCharMap = (map, text) => {
  let out = "";
  for (let c of text.split("")) {
    if (map[c] !== undefined) out += map[c];
    else if (map[c.toLowerCase()] !== undefined) out += map[c.toLowerCase()];
    else out += c;
  }
  return out;
};

/* eslint-disable */
const taiVietCharMap = {
  0: "ᦲ",
  1: "᧒",
  2: "ᒿ",
  3: "ᗱ",
  4: "ᔰ",
  5: "Ƽ",
  6: "ᦆ",
  7: "ᒣ",
  8: "Ზ",
  9: "ၦ",
  a: "ꪖ",
  b: "᥇",
  c: "ᥴ",
  d: "ᦔ",
  e: "ꫀ",
  f: "ᠻ",
  g: "ᧁ",
  h: "ꫝ",
  i: "꠸",
  j: "꠹",
  k: "ᛕ",
  l: "ꪶ",
  m: "ꪑ",
  n: "ꪀ",
  o: "ꪮ",
  p: "ρ",
  q: "ꪇ",
  r: "᥅",
  s: "ᦓ",
  t: "ꪻ",
  u: "ꪊ",
  v: "ꪜ",
  w: "᭙",
  x: "᥊",
  y: "ꪗ",
  z: "ƺ",
  A: "ꪖ",
  B: "᥇",
  C: "ᥴ",
  D: "ᦔ",
  E: "ꫀ",
  F: "ᠻ",
  G: "ᧁ",
  H: "ꫝ",
  I: "꠸",
  J: "꠹",
  K: "ᛕ",
  L: "ꪶ",
  M: "ꪑ",
  N: "ꪀ",
  O: "ꪮ",
  P: "ρ",
  Q: "ꪇ",
  R: "᥅",
  S: "ᦓ",
  T: "ꪻ",
  U: "ꪊ",
  V: "ꪜ",
  W: "᭙",
  X: "᥊",
  Y: "ꪗ",
  Z: "ƺ",
};

const futureAlienCharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "ᗩ",
  b: "ᗷ",
  c: "ᑢ",
  d: "ᕲ",
  e: "ᘿ",
  f: "ᖴ",
  g: "ᘜ",
  h: "ᕼ",
  i: "ᓰ",
  j: "ᒚ",
  k: "ᖽᐸ",
  l: "ᒪ",
  m: "ᘻ",
  n: "ᘉ",
  o: "ᓍ",
  p: "ᕵ",
  q: "ᕴ",
  r: "ᖇ",
  s: "S",
  t: "ᖶ",
  u: "ᑘ",
  v: "ᐺ",
  w: "ᘺ",
  x: "᙭",
  y: "ᖻ",
  z: "ᗱ",
  A: "ᗩ",
  B: "ᗷ",
  C: "ᑢ",
  D: "ᕲ",
  E: "ᘿ",
  F: "ᖴ",
  G: "ᘜ",
  H: "ᕼ",
  I: "ᓰ",
  J: "ᒚ",
  K: "ᖽᐸ",
  L: "ᒪ",
  M: "ᘻ",
  N: "ᘉ",
  O: "ᓍ",
  P: "ᕵ",
  Q: "ᕴ",
  R: "ᖇ",
  S: "S",
  T: "ᖶ",
  U: "ᑘ",
  V: "ᐺ",
  W: "ᘺ",
  X: "᙭",
  Y: "ᖻ",
  Z: "ᗱ",
};
const squiggle6CharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "ค",
  b: "๖",
  c: "¢",
  d: "໓",
  e: "ē",
  f: "f",
  g: "ງ",
  h: "h",
  i: "i",
  j: "ว",
  k: "k",
  l: "l",
  m: "๓",
  n: "ຖ",
  o: "໐",
  p: "p",
  q: "๑",
  r: "r",
  s: "Ş",
  t: "t",
  u: "น",
  v: "ง",
  w: "ຟ",
  x: "x",
  y: "ฯ",
  z: "ຊ",
  A: "ค",
  B: "๖",
  C: "¢",
  D: "໓",
  E: "ē",
  F: "f",
  G: "ງ",
  H: "h",
  I: "i",
  J: "ว",
  K: "k",
  L: "l",
  M: "๓",
  N: "ຖ",
  O: "໐",
  P: "p",
  Q: "๑",
  R: "r",
  S: "Ş",
  T: "t",
  U: "น",
  V: "ง",
  W: "ຟ",
  X: "x",
  Y: "ฯ",
  Z: "ຊ",
};
const squiggle5CharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "ą",
  b: "ც",
  c: "ƈ",
  d: "ɖ",
  e: "ɛ",
  f: "ʄ",
  g: "ɠ",
  h: "ɧ",
  i: "ı",
  j: "ʝ",
  k: "ƙ",
  l: "Ɩ",
  m: "ɱ",
  n: "ŋ",
  o: "ơ",
  p: "℘",
  q: "զ",
  r: "ཞ",
  s: "ʂ",
  t: "ɬ",
  u: "ų",
  v: "۷",
  w: "ῳ",
  x: "ҳ",
  y: "ყ",
  z: "ʑ",
  A: "ą",
  B: "ც",
  C: "ƈ",
  D: "ɖ",
  E: "ɛ",
  F: "ʄ",
  G: "ɠ",
  H: "ɧ",
  I: "ı",
  J: "ʝ",
  K: "ƙ",
  L: "Ɩ",
  M: "ɱ",
  N: "ŋ",
  O: "ơ",
  P: "℘",
  Q: "զ",
  R: "ཞ",
  S: "ʂ",
  T: "ɬ",
  U: "ų",
  V: "۷",
  W: "ῳ",
  X: "ҳ",
  Y: "ყ",
  Z: "ʑ",
};
const asianStyle2CharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "ﾑ",
  b: "乃",
  c: "ᄃ",
  d: "り",
  e: "乇",
  f: "ｷ",
  g: "ム",
  h: "ん",
  i: "ﾉ",
  j: "ﾌ",
  k: "ズ",
  l: "ﾚ",
  m: "ﾶ",
  n: "刀",
  o: "の",
  p: "ｱ",
  q: "ゐ",
  r: "尺",
  s: "丂",
  t: "ｲ",
  u: "ひ",
  v: "√",
  w: "W",
  x: "ﾒ",
  y: "ﾘ",
  z: "乙",
  A: "ﾑ",
  B: "乃",
  C: "ᄃ",
  D: "り",
  E: "乇",
  F: "ｷ",
  G: "ム",
  H: "ん",
  I: "ﾉ",
  J: "ﾌ",
  K: "ズ",
  L: "ﾚ",
  M: "ﾶ",
  N: "刀",
  O: "の",
  P: "ｱ",
  Q: "ゐ",
  R: "尺",
  S: "丂",
  T: "ｲ",
  U: "ひ",
  V: "√",
  W: "W",
  X: "ﾒ",
  Y: "ﾘ",
  Z: "乙",
};
const asianStyleCharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "卂",
  b: "乃",
  c: "匚",
  d: "ᗪ",
  e: "乇",
  f: "千",
  g: "Ꮆ",
  h: "卄",
  i: "丨",
  j: "ﾌ",
  k: "Ҝ",
  l: "ㄥ",
  m: "爪",
  n: "几",
  o: "ㄖ",
  p: "卩",
  q: "Ɋ",
  r: "尺",
  s: "丂",
  t: "ㄒ",
  u: "ㄩ",
  v: "ᐯ",
  w: "山",
  x: "乂",
  y: "ㄚ",
  z: "乙",
  A: "卂",
  B: "乃",
  C: "匚",
  D: "ᗪ",
  E: "乇",
  F: "千",
  G: "Ꮆ",
  H: "卄",
  I: "丨",
  J: "ﾌ",
  K: "Ҝ",
  L: "ㄥ",
  M: "爪",
  N: "几",
  O: "ㄖ",
  P: "卩",
  Q: "Ɋ",
  R: "尺",
  S: "丂",
  T: "ㄒ",
  U: "ㄩ",
  V: "ᐯ",
  W: "山",
  X: "乂",
  Y: "ㄚ",
  Z: "乙",
};
const squaresCharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "🄰",
  b: "🄱",
  c: "🄲",
  d: "🄳",
  e: "🄴",
  f: "🄵",
  g: "🄶",
  h: "🄷",
  i: "🄸",
  j: "🄹",
  k: "🄺",
  l: "🄻",
  m: "🄼",
  n: "🄽",
  o: "🄾",
  p: "🄿",
  q: "🅀",
  r: "🅁",
  s: "🅂",
  t: "🅃",
  u: "🅄",
  v: "🅅",
  w: "🅆",
  x: "🅇",
  y: "🅈",
  z: "🅉",
  A: "🄰",
  B: "🄱",
  C: "🄲",
  D: "🄳",
  E: "🄴",
  F: "🄵",
  G: "🄶",
  H: "🄷",
  I: "🄸",
  J: "🄹",
  K: "🄺",
  L: "🄻",
  M: "🄼",
  N: "🄽",
  O: "🄾",
  P: "🄿",
  Q: "🅀",
  R: "🅁",
  S: "🅂",
  T: "🅃",
  U: "🅄",
  V: "🅅",
  W: "🅆",
  X: "🅇",
  Y: "🅈",
  Z: "🅉",
};
const squiggle4CharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "Ꮧ",
  b: "Ᏸ",
  c: "ፈ",
  d: "Ꮄ",
  e: "Ꮛ",
  f: "Ꭶ",
  g: "Ꮆ",
  h: "Ꮒ",
  i: "Ꭵ",
  j: "Ꮰ",
  k: "Ꮶ",
  l: "Ꮭ",
  m: "Ꮇ",
  n: "Ꮑ",
  o: "Ꭷ",
  p: "Ꭾ",
  q: "Ꭴ",
  r: "Ꮢ",
  s: "Ꮥ",
  t: "Ꮦ",
  u: "Ꮼ",
  v: "Ꮙ",
  w: "Ꮗ",
  x: "ጀ",
  y: "Ꭹ",
  z: "ፚ",
  A: "Ꮧ",
  B: "Ᏸ",
  C: "ፈ",
  D: "Ꮄ",
  E: "Ꮛ",
  F: "Ꭶ",
  G: "Ꮆ",
  H: "Ꮒ",
  I: "Ꭵ",
  J: "Ꮰ",
  K: "Ꮶ",
  L: "Ꮭ",
  M: "Ꮇ",
  N: "Ꮑ",
  O: "Ꭷ",
  P: "Ꭾ",
  Q: "Ꭴ",
  R: "Ꮢ",
  S: "Ꮥ",
  T: "Ꮦ",
  U: "Ꮼ",
  V: "Ꮙ",
  W: "Ꮗ",
  X: "ጀ",
  Y: "Ꭹ",
  Z: "ፚ",
};
const neonCharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "ᗩ",
  b: "ᗷ",
  c: "ᑕ",
  d: "ᗪ",
  e: "E",
  f: "ᖴ",
  g: "G",
  h: "ᕼ",
  i: "I",
  j: "ᒍ",
  k: "K",
  l: "ᒪ",
  m: "ᗰ",
  n: "ᑎ",
  o: "O",
  p: "ᑭ",
  q: "ᑫ",
  r: "ᖇ",
  s: "ᔕ",
  t: "T",
  u: "ᑌ",
  v: "ᐯ",
  w: "ᗯ",
  x: "᙭",
  y: "Y",
  z: "ᘔ",
  A: "ᗩ",
  B: "ᗷ",
  C: "ᑕ",
  D: "ᗪ",
  E: "E",
  F: "ᖴ",
  G: "G",
  H: "ᕼ",
  I: "I",
  J: "ᒍ",
  K: "K",
  L: "ᒪ",
  M: "ᗰ",
  N: "ᑎ",
  O: "O",
  P: "ᑭ",
  Q: "ᑫ",
  R: "ᖇ",
  S: "ᔕ",
  T: "T",
  U: "ᑌ",
  V: "ᐯ",
  W: "ᗯ",
  X: "᙭",
  Y: "Y",
  Z: "ᘔ",
};
const squiggle3CharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "ǟ",
  b: "ɮ",
  c: "ƈ",
  d: "ɖ",
  e: "ɛ",
  f: "ʄ",
  g: "ɢ",
  h: "ɦ",
  i: "ɨ",
  j: "ʝ",
  k: "ӄ",
  l: "ʟ",
  m: "ʍ",
  n: "ռ",
  o: "օ",
  p: "ք",
  q: "զ",
  r: "ʀ",
  s: "ֆ",
  t: "ȶ",
  u: "ʊ",
  v: "ʋ",
  w: "ա",
  x: "Ӽ",
  y: "ʏ",
  z: "ʐ",
  A: "ǟ",
  B: "ɮ",
  C: "ƈ",
  D: "ɖ",
  E: "ɛ",
  F: "ʄ",
  G: "ɢ",
  H: "ɦ",
  I: "ɨ",
  J: "ʝ",
  K: "ӄ",
  L: "ʟ",
  M: "ʍ",
  N: "ռ",
  O: "օ",
  P: "ք",
  Q: "զ",
  R: "ʀ",
  S: "ֆ",
  T: "ȶ",
  U: "ʊ",
  V: "ʋ",
  W: "ա",
  X: "Ӽ",
  Y: "ʏ",
  Z: "ʐ",
};
const monospaceCharMap = {
  0: "𝟶",
  1: "𝟷",
  2: "𝟸",
  3: "𝟹",
  4: "𝟺",
  5: "𝟻",
  6: "𝟼",
  7: "𝟽",
  8: "𝟾",
  9: "𝟿",
  a: "𝚊",
  b: "𝚋",
  c: "𝚌",
  d: "𝚍",
  e: "𝚎",
  f: "𝚏",
  g: "𝚐",
  h: "𝚑",
  i: "𝚒",
  j: "𝚓",
  k: "𝚔",
  l: "𝚕",
  m: "𝚖",
  n: "𝚗",
  o: "𝚘",
  p: "𝚙",
  q: "𝚚",
  r: "𝚛",
  s: "𝚜",
  t: "𝚝",
  u: "𝚞",
  v: "𝚟",
  w: "𝚠",
  x: "𝚡",
  y: "𝚢",
  z: "𝚣",
  A: "𝙰",
  B: "𝙱",
  C: "𝙲",
  D: "𝙳",
  E: "𝙴",
  F: "𝙵",
  G: "𝙶",
  H: "𝙷",
  I: "𝙸",
  J: "𝙹",
  K: "𝙺",
  L: "𝙻",
  M: "𝙼",
  N: "𝙽",
  O: "𝙾",
  P: "𝙿",
  Q: "𝚀",
  R: "𝚁",
  S: "𝚂",
  T: "𝚃",
  U: "𝚄",
  V: "𝚅",
  W: "𝚆",
  X: "𝚇",
  Y: "𝚈",
  Z: "𝚉",
};
const boldItalicCharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "𝙖",
  b: "𝙗",
  c: "𝙘",
  d: "𝙙",
  e: "𝙚",
  f: "𝙛",
  g: "𝙜",
  h: "𝙝",
  i: "𝙞",
  j: "𝙟",
  k: "𝙠",
  l: "𝙡",
  m: "𝙢",
  n: "𝙣",
  o: "𝙤",
  p: "𝙥",
  q: "𝙦",
  r: "𝙧",
  s: "𝙨",
  t: "𝙩",
  u: "𝙪",
  v: "𝙫",
  w: "𝙬",
  x: "𝙭",
  y: "𝙮",
  z: "𝙯",
  A: "𝘼",
  B: "𝘽",
  C: "𝘾",
  D: "𝘿",
  E: "𝙀",
  F: "𝙁",
  G: "𝙂",
  H: "𝙃",
  I: "𝙄",
  J: "𝙅",
  K: "𝙆",
  L: "𝙇",
  M: "𝙈",
  N: "𝙉",
  O: "𝙊",
  P: "𝙋",
  Q: "𝙌",
  R: "𝙍",
  S: "𝙎",
  T: "𝙏",
  U: "𝙐",
  V: "𝙑",
  W: "𝙒",
  X: "𝙓",
  Y: "𝙔",
  Z: "𝙕",
};
const boldCharMap = {
  0: "𝟎",
  1: "𝟏",
  2: "𝟐",
  3: "𝟑",
  4: "𝟒",
  5: "𝟓",
  6: "𝟔",
  7: "𝟕",
  8: "𝟖",
  9: "𝟗",
  a: "𝐚",
  b: "𝐛",
  c: "𝐜",
  d: "𝐝",
  e: "𝐞",
  f: "𝐟",
  g: "𝐠",
  h: "𝐡",
  i: "𝐢",
  j: "𝐣",
  k: "𝐤",
  l: "𝐥",
  m: "𝐦",
  n: "𝐧",
  o: "𝐨",
  p: "𝐩",
  q: "𝐪",
  r: "𝐫",
  s: "𝐬",
  t: "𝐭",
  u: "𝐮",
  v: "𝐯",
  w: "𝐰",
  x: "𝐱",
  y: "𝐲",
  z: "𝐳",
  A: "𝐀",
  B: "𝐁",
  C: "𝐂",
  D: "𝐃",
  E: "𝐄",
  F: "𝐅",
  G: "𝐆",
  H: "𝐇",
  I: "𝐈",
  J: "𝐉",
  K: "𝐊",
  L: "𝐋",
  M: "𝐌",
  N: "𝐍",
  O: "𝐎",
  P: "𝐏",
  Q: "𝐐",
  R: "𝐑",
  S: "𝐒",
  T: "𝐓",
  U: "𝐔",
  V: "𝐕",
  W: "𝐖",
  X: "𝐗",
  Y: "𝐘",
  Z: "𝐙",
};
const boldSansCharMap = {
  0: "𝟬",
  1: "𝟭",
  2: "𝟮",
  3: "𝟯",
  4: "𝟰",
  5: "𝟱",
  6: "𝟲",
  7: "𝟳",
  8: "𝟴",
  9: "𝟵",
  a: "𝗮",
  b: "𝗯",
  c: "𝗰",
  d: "𝗱",
  e: "𝗲",
  f: "𝗳",
  g: "𝗴",
  h: "𝗵",
  i: "𝗶",
  j: "𝗷",
  k: "𝗸",
  l: "𝗹",
  m: "𝗺",
  n: "𝗻",
  o: "𝗼",
  p: "𝗽",
  q: "𝗾",
  r: "𝗿",
  s: "𝘀",
  t: "𝘁",
  u: "𝘂",
  v: "𝘃",
  w: "𝘄",
  x: "𝘅",
  y: "𝘆",
  z: "𝘇",
  A: "𝗔",
  B: "𝗕",
  C: "𝗖",
  D: "𝗗",
  E: "𝗘",
  F: "𝗙",
  G: "𝗚",
  H: "𝗛",
  I: "𝗜",
  J: "𝗝",
  K: "𝗞",
  L: "𝗟",
  M: "𝗠",
  N: "𝗡",
  O: "𝗢",
  P: "𝗣",
  Q: "𝗤",
  R: "𝗥",
  S: "𝗦",
  T: "𝗧",
  U: "𝗨",
  V: "𝗩",
  W: "𝗪",
  X: "𝗫",
  Y: "𝗬",
  Z: "𝗭",
};
const italicCharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "𝘢",
  b: "𝘣",
  c: "𝘤",
  d: "𝘥",
  e: "𝘦",
  f: "𝘧",
  g: "𝘨",
  h: "𝘩",
  i: "𝘪",
  j: "𝘫",
  k: "𝘬",
  l: "𝘭",
  m: "𝘮",
  n: "𝘯",
  o: "𝘰",
  p: "𝘱",
  q: "𝘲",
  r: "𝘳",
  s: "𝘴",
  t: "𝘵",
  u: "𝘶",
  v: "𝘷",
  w: "𝘸",
  x: "𝘹",
  y: "𝘺",
  z: "𝘻",
  A: "𝘈",
  B: "𝘉",
  C: "𝘊",
  D: "𝘋",
  E: "𝘌",
  F: "𝘍",
  G: "𝘎",
  H: "𝘏",
  I: "𝘐",
  J: "𝘑",
  K: "𝘒",
  L: "𝘓",
  M: "𝘔",
  N: "𝘕",
  O: "𝘖",
  P: "𝘗",
  Q: "𝘘",
  R: "𝘙",
  S: "𝘚",
  T: "𝘛",
  U: "𝘜",
  V: "𝘝",
  W: "𝘞",
  X: "𝘟",
  Y: "𝘠",
  Z: "𝘡",
};
const squiggle2CharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "α",
  b: "Ⴆ",
  c: "ƈ",
  d: "ԃ",
  e: "ҽ",
  f: "ϝ",
  g: "ɠ",
  h: "ԋ",
  i: "ι",
  j: "ʝ",
  k: "ƙ",
  l: "ʅ",
  m: "ɱ",
  n: "ɳ",
  o: "σ",
  p: "ρ",
  q: "ϙ",
  r: "ɾ",
  s: "ʂ",
  t: "ƚ",
  u: "υ",
  v: "ʋ",
  w: "ɯ",
  x: "x",
  y: "ყ",
  z: "ȥ",
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  E: "E",
  F: "F",
  G: "G",
  H: "H",
  I: "I",
  J: "J",
  K: "K",
  L: "L",
  M: "M",
  N: "N",
  O: "O",
  P: "P",
  Q: "Q",
  R: "R",
  S: "S",
  T: "T",
  U: "U",
  V: "V",
  W: "W",
  X: "X",
  Y: "Y",
  Z: "Z",
};
const currencyCharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "₳",
  b: "฿",
  c: "₵",
  d: "Đ",
  e: "Ɇ",
  f: "₣",
  g: "₲",
  h: "Ⱨ",
  i: "ł",
  j: "J",
  k: "₭",
  l: "Ⱡ",
  m: "₥",
  n: "₦",
  o: "Ø",
  p: "₱",
  q: "Q",
  r: "Ɽ",
  s: "₴",
  t: "₮",
  u: "Ʉ",
  v: "V",
  w: "₩",
  x: "Ӿ",
  y: "Ɏ",
  z: "Ⱬ",
  A: "₳",
  B: "฿",
  C: "₵",
  D: "Đ",
  E: "Ɇ",
  F: "₣",
  G: "₲",
  H: "Ⱨ",
  I: "ł",
  J: "J",
  K: "₭",
  L: "Ⱡ",
  M: "₥",
  N: "₦",
  O: "Ø",
  P: "₱",
  Q: "Q",
  R: "Ɽ",
  S: "₴",
  T: "₮",
  U: "Ʉ",
  V: "V",
  W: "₩",
  X: "Ӿ",
  Y: "Ɏ",
  Z: "Ⱬ",
};
const symbolsCharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "å",
  b: "ß",
  c: "¢",
  d: "Ð",
  e: "ê",
  f: "£",
  g: "g",
  h: "h",
  i: "ï",
  j: "j",
  k: "k",
  l: "l",
  m: "m",
  n: "ñ",
  o: "ð",
  p: "þ",
  q: "q",
  r: "r",
  s: "§",
  t: "†",
  u: "µ",
  v: "v",
  w: "w",
  x: "x",
  y: "¥",
  z: "z",
  A: "Ä",
  B: "ß",
  C: "Ç",
  D: "Ð",
  E: "È",
  F: "£",
  G: "G",
  H: "H",
  I: "Ì",
  J: "J",
  K: "K",
  L: "L",
  M: "M",
  N: "ñ",
  O: "Ö",
  P: "þ",
  Q: "Q",
  R: "R",
  S: "§",
  T: "†",
  U: "Ú",
  V: "V",
  W: "W",
  X: "×",
  Y: "¥",
  Z: "Z",
};
const greekCharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "α",
  b: "в",
  c: "¢",
  d: "∂",
  e: "є",
  f: "ƒ",
  g: "g",
  h: "н",
  i: "ι",
  j: "נ",
  k: "к",
  l: "ℓ",
  m: "м",
  n: "η",
  o: "σ",
  p: "ρ",
  q: "q",
  r: "я",
  s: "ѕ",
  t: "т",
  u: "υ",
  v: "ν",
  w: "ω",
  x: "χ",
  y: "у",
  z: "z",
  A: "α",
  B: "в",
  C: "¢",
  D: "∂",
  E: "є",
  F: "ƒ",
  G: "g",
  H: "н",
  I: "ι",
  J: "נ",
  K: "к",
  L: "ℓ",
  M: "м",
  N: "η",
  O: "σ",
  P: "ρ",
  Q: "q",
  R: "я",
  S: "ѕ",
  T: "т",
  U: "υ",
  V: "ν",
  W: "ω",
  X: "χ",
  Y: "у",
  Z: "z",
};
const bentTextCharMap = {
  0: "⊘",
  1: "𝟙",
  2: "ϩ",
  3: "Ӡ",
  4: "५",
  5: "Ƽ",
  6: "Ϭ",
  7: "7",
  8: "𝟠",
  9: "९",
  a: "ą",
  b: "ҍ",
  c: "ç",
  d: "ժ",
  e: "ҽ",
  f: "ƒ",
  g: "ց",
  h: "հ",
  i: "ì",
  j: "ʝ",
  k: "ҟ",
  l: "Ӏ",
  m: "ʍ",
  n: "ղ",
  o: "օ",
  p: "ք",
  q: "զ",
  r: "ɾ",
  s: "ʂ",
  t: "է",
  u: "մ",
  v: "ѵ",
  w: "ա",
  x: "×",
  y: "վ",
  z: "Հ",
  A: "Ⱥ",
  B: "β",
  C: "↻",
  D: "Ꭰ",
  E: "Ɛ",
  F: "Ƒ",
  G: "Ɠ",
  H: "Ƕ",
  I: "į",
  J: "ل",
  K: "Ҡ",
  L: "Ꝉ",
  M: "Ɱ",
  N: "ហ",
  O: "ට",
  P: "φ",
  Q: "Ҩ",
  R: "འ",
  S: "Ϛ",
  T: "Ͳ",
  U: "Ա",
  V: "Ỽ",
  W: "చ",
  X: "ჯ",
  Y: "Ӌ",
  Z: "ɀ",
};
const upperAnglesCharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "Λ",
  b: "B",
  c: "ᄃ",
  d: "D",
  e: "Σ",
  f: "F",
  g: "G",
  h: "Ή",
  i: "I",
  j: "J",
  k: "K",
  l: "ᄂ",
  m: "M",
  n: "П",
  o: "Ө",
  p: "P",
  q: "Q",
  r: "Я",
  s: "Ƨ",
  t: "Ƭ",
  u: "Ц",
  v: "V",
  w: "Щ",
  x: "X",
  y: "Y",
  z: "Z",
  A: "Λ",
  B: "B",
  C: "ᄃ",
  D: "D",
  E: "Σ",
  F: "F",
  G: "G",
  H: "Ή",
  I: "I",
  J: "J",
  K: "K",
  L: "ᄂ",
  M: "M",
  N: "П",
  O: "Ө",
  P: "P",
  Q: "Q",
  R: "Я",
  S: "Ƨ",
  T: "Ƭ",
  U: "Ц",
  V: "V",
  W: "Щ",
  X: "X",
  Y: "Y",
  Z: "Z",
};
const subscriptCharMap = {
  0: "₀",
  1: "₁",
  2: "₂",
  3: "₃",
  4: "₄",
  5: "₅",
  6: "₆",
  7: "₇",
  8: "₈",
  9: "₉",
  a: "ₐ",
  b: "b",
  c: "c",
  d: "d",
  e: "ₑ",
  f: "f",
  g: "g",
  h: "ₕ",
  i: "ᵢ",
  j: "ⱼ",
  k: "ₖ",
  l: "ₗ",
  m: "ₘ",
  n: "ₙ",
  o: "ₒ",
  p: "ₚ",
  q: "q",
  r: "ᵣ",
  s: "ₛ",
  t: "ₜ",
  u: "ᵤ",
  v: "ᵥ",
  w: "w",
  x: "ₓ",
  y: "y",
  z: "z",
  A: "ₐ",
  B: "B",
  C: "C",
  D: "D",
  E: "ₑ",
  F: "F",
  G: "G",
  H: "ₕ",
  I: "ᵢ",
  J: "ⱼ",
  K: "ₖ",
  L: "ₗ",
  M: "ₘ",
  N: "ₙ",
  O: "ₒ",
  P: "ₚ",
  Q: "Q",
  R: "ᵣ",
  S: "ₛ",
  T: "ₜ",
  U: "ᵤ",
  V: "ᵥ",
  W: "W",
  X: "ₓ",
  Y: "Y",
  Z: "Z",
  "+": "₊",
  "-": "₋",
  "=": "₌",
  "(": "₍",
  ")": "₎",
};
const superscriptCharMap = {
  0: "⁰",
  1: "¹",
  2: "²",
  3: "³",
  4: "⁴",
  5: "⁵",
  6: "⁶",
  7: "⁷",
  8: "⁸",
  9: "⁹",
  a: "ᵃ",
  b: "ᵇ",
  c: "ᶜ",
  d: "ᵈ",
  e: "ᵉ",
  f: "ᶠ",
  g: "ᵍ",
  h: "ʰ",
  i: "ⁱ",
  j: "ʲ",
  k: "ᵏ",
  l: "ˡ",
  m: "ᵐ",
  n: "ⁿ",
  o: "ᵒ",
  p: "ᵖ",
  q: "q",
  r: "ʳ",
  s: "ˢ",
  t: "ᵗ",
  u: "ᵘ",
  v: "ᵛ",
  w: "ʷ",
  x: "ˣ",
  y: "ʸ",
  z: "ᶻ",
  A: "ᴬ",
  B: "ᴮ",
  C: "ᶜ",
  D: "ᴰ",
  E: "ᴱ",
  F: "ᶠ",
  G: "ᴳ",
  H: "ᴴ",
  I: "ᴵ",
  J: "ᴶ",
  K: "ᴷ",
  L: "ᴸ",
  M: "ᴹ",
  N: "ᴺ",
  O: "ᴼ",
  P: "ᴾ",
  Q: "Q",
  R: "ᴿ",
  S: "ˢ",
  T: "ᵀ",
  U: "ᵁ",
  V: "ⱽ",
  W: "ᵂ",
  X: "ˣ",
  Y: "ʸ",
  Z: "ᶻ",
  "+": "⁺",
  "-": "⁻",
  "=": "⁼",
  "(": "⁽",
  ")": "⁾",
};
const squiggleCharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "ค",
  b: "๒",
  c: "ς",
  d: "๔",
  e: "є",
  f: "Ŧ",
  g: "ﻮ",
  h: "ђ",
  i: "เ",
  j: "ן",
  k: "к",
  l: "ɭ",
  m: "๓",
  n: "ภ",
  o: "๏",
  p: "ק",
  q: "ợ",
  r: "г",
  s: "ร",
  t: "Շ",
  u: "ย",
  v: "ש",
  w: "ฬ",
  x: "א",
  y: "ץ",
  z: "չ",
  A: "ค",
  B: "๒",
  C: "ς",
  D: "๔",
  E: "є",
  F: "Ŧ",
  G: "ﻮ",
  H: "ђ",
  I: "เ",
  J: "ן",
  K: "к",
  L: "ɭ",
  M: "๓",
  N: "ภ",
  O: "๏",
  P: "ק",
  Q: "ợ",
  R: "г",
  S: "ร",
  T: "Շ",
  U: "ย",
  V: "ש",
  W: "ฬ",
  X: "א",
  Y: "ץ",
  Z: "չ",
};
const doubleStruckCharMap = {
  0: "𝟘",
  1: "𝟙",
  2: "𝟚",
  3: "𝟛",
  4: "𝟜",
  5: "𝟝",
  6: "𝟞",
  7: "𝟟",
  8: "𝟠",
  9: "𝟡",
  a: "𝕒",
  b: "𝕓",
  c: "𝕔",
  d: "𝕕",
  e: "𝕖",
  f: "𝕗",
  g: "𝕘",
  h: "𝕙",
  i: "𝕚",
  j: "𝕛",
  k: "𝕜",
  l: "𝕝",
  m: "𝕞",
  n: "𝕟",
  o: "𝕠",
  p: "𝕡",
  q: "𝕢",
  r: "𝕣",
  s: "𝕤",
  t: "𝕥",
  u: "𝕦",
  v: "𝕧",
  w: "𝕨",
  x: "𝕩",
  y: "𝕪",
  z: "𝕫",
  A: "𝔸",
  B: "𝔹",
  C: "ℂ",
  D: "𝔻",
  E: "𝔼",
  F: "𝔽",
  G: "𝔾",
  H: "ℍ",
  I: "𝕀",
  J: "𝕁",
  K: "𝕂",
  L: "𝕃",
  M: "𝕄",
  N: "ℕ",
  O: "𝕆",
  P: "ℙ",
  Q: "ℚ",
  R: "ℝ",
  S: "𝕊",
  T: "𝕋",
  U: "𝕌",
  V: "𝕍",
  W: "𝕎",
  X: "𝕏",
  Y: "𝕐",
  Z: "ℤ",
};
const medievalCharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "𝖆",
  b: "𝖇",
  c: "𝖈",
  d: "𝖉",
  e: "𝖊",
  f: "𝖋",
  g: "𝖌",
  h: "𝖍",
  i: "𝖎",
  j: "𝖏",
  k: "𝖐",
  l: "𝖑",
  m: "𝖒",
  n: "𝖓",
  o: "𝖔",
  p: "𝖕",
  q: "𝖖",
  r: "𝖗",
  s: "𝖘",
  t: "𝖙",
  u: "𝖚",
  v: "𝖛",
  w: "𝖜",
  x: "𝖝",
  y: "𝖞",
  z: "𝖟",
  A: "𝕬",
  B: "𝕭",
  C: "𝕮",
  D: "𝕯",
  E: "𝕰",
  F: "𝕱",
  G: "𝕲",
  H: "𝕳",
  I: "𝕴",
  J: "𝕵",
  K: "𝕶",
  L: "𝕷",
  M: "𝕸",
  N: "𝕹",
  O: "𝕺",
  P: "𝕻",
  Q: "𝕼",
  R: "𝕽",
  S: "𝕾",
  T: "𝕿",
  U: "𝖀",
  V: "𝖁",
  W: "𝖂",
  X: "𝖃",
  Y: "𝖄",
  Z: "𝖅",
};
const invertedSquaresCharMap = {
  q: "🆀",
  w: "🆆",
  e: "🅴",
  r: "🆁",
  t: "🆃",
  y: "🆈",
  u: "🆄",
  i: "🅸",
  o: "🅾",
  p: "🅿",
  a: "🅰",
  s: "🆂",
  d: "🅳",
  f: "🅵",
  g: "🅶",
  h: "🅷",
  j: "🅹",
  k: "🅺",
  l: "🅻",
  z: "🆉",
  x: "🆇",
  c: "🅲",
  v: "🆅",
  b: "🅱",
  n: "🅽",
  m: "🅼",
};
const cursiveCharMap = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  a: "𝓪",
  b: "𝓫",
  c: "𝓬",
  d: "𝓭",
  e: "𝓮",
  f: "𝓯",
  g: "𝓰",
  h: "𝓱",
  i: "𝓲",
  j: "𝓳",
  k: "𝓴",
  l: "𝓵",
  m: "𝓶",
  n: "𝓷",
  o: "𝓸",
  p: "𝓹",
  q: "𝓺",
  r: "𝓻",
  s: "𝓼",
  t: "𝓽",
  u: "𝓾",
  v: "𝓿",
  w: "𝔀",
  x: "𝔁",
  y: "𝔂",
  z: "𝔃",
  A: "𝓐",
  B: "𝓑",
  C: "𝓒",
  D: "𝓓",
  E: "𝓔",
  F: "𝓕",
  G: "𝓖",
  H: "𝓗",
  I: "𝓘",
  J: "𝓙",
  K: "𝓚",
  L: "𝓛",
  M: "𝓜",
  N: "𝓝",
  O: "𝓞",
  P: "𝓟",
  Q: "𝓠",
  R: "𝓡",
  S: "𝓢",
  T: "𝓣",
  U: "𝓤",
  V: "𝓥",
  W: "𝓦",
  X: "𝓧",
  Y: "𝓨",
  Z: "𝓩",
};
const oldEnglishCharMap = {
  a: "𝔞",
  b: "𝔟",
  c: "𝔠",
  d: "𝔡",
  e: "𝔢",
  f: "𝔣",
  g: "𝔤",
  h: "𝔥",
  i: "𝔦",
  j: "𝔧",
  k: "𝔨",
  l: "𝔩",
  m: "𝔪",
  n: "𝔫",
  o: "𝔬",
  p: "𝔭",
  q: "𝔮",
  r: "𝔯",
  s: "𝔰",
  t: "𝔱",
  u: "𝔲",
  v: "𝔳",
  w: "𝔴",
  x: "𝔵",
  y: "𝔶",
  z: "𝔷",
  A: "𝔄",
  B: "𝔅",
  C: "ℭ",
  D: "𝔇",
  E: "𝔈",
  F: "𝔉",
  G: "𝔊",
  H: "ℌ",
  I: "ℑ",
  J: "𝔍",
  K: "𝔎",
  L: "𝔏",
  M: "𝔐",
  N: "𝔑",
  O: "𝔒",
  P: "𝔓",
  Q: "𝔔",
  R: "ℜ",
  S: "𝔖",
  T: "𝔗",
  U: "𝔘",
  V: "𝔙",
  W: "𝔚",
  X: "𝔛",
  Y: "𝔜",
  Z: "ℨ",
};
const wideTextCharMap = {
  "`": "`",
  1: "１",
  2: "２",
  3: "３",
  4: "４",
  5: "５",
  6: "６",
  7: "７",
  8: "８",
  9: "９",
  0: "０",
  "-": "－",
  "=": "＝",
  "~": "~",
  "!": "！",
  "@": "＠",
  "#": "＃",
  $: "＄",
  "%": "％",
  "^": "^",
  "&": "＆",
  "*": "＊",
  "(": "（",
  ")": "）",
  _: "_",
  "+": "＋",
  q: "ｑ",
  w: "ｗ",
  e: "ｅ",
  r: "ｒ",
  t: "ｔ",
  y: "ｙ",
  u: "ｕ",
  i: "ｉ",
  o: "ｏ",
  p: "ｐ",
  "[": "[",
  "]": "]",
  "\\": "\\",
  Q: "Ｑ",
  W: "Ｗ",
  E: "Ｅ",
  R: "Ｒ",
  T: "Ｔ",
  Y: "Ｙ",
  U: "Ｕ",
  I: "Ｉ",
  O: "Ｏ",
  P: "Ｐ",
  "{": "{",
  "}": "}",
  "|": "|",
  a: "ａ",
  s: "ｓ",
  d: "ｄ",
  f: "ｆ",
  g: "ｇ",
  h: "ｈ",
  j: "ｊ",
  k: "ｋ",
  l: "ｌ",
  ";": "；",
  "'": "＇",
  A: "Ａ",
  S: "Ｓ",
  D: "Ｄ",
  F: "Ｆ",
  G: "Ｇ",
  H: "Ｈ",
  J: "Ｊ",
  K: "Ｋ",
  L: "Ｌ",
  ":": "：",
  '"': '"',
  z: "ｚ",
  x: "ｘ",
  c: "ｃ",
  v: "ｖ",
  b: "ｂ",
  n: "ｎ",
  m: "ｍ",
  ",": "，",
  ".": "．",
  "/": "／",
  Z: "Ｚ",
  X: "Ｘ",
  C: "Ｃ",
  V: "Ｖ",
  B: "Ｂ",
  N: "Ｎ",
  M: "Ｍ",
  "<": "<",
  ">": ">",
  "?": "？",
};

// CUTE TEXT:
export const cuteText = (text) => {
  if (text.trim() === "") return "";
  qi = -1;
  var bounds = boundingString(Math.floor(Math.random() * 2) + 1);
  return (
    bounds +
    "  🎀  " +
    text
      .split(/([!?.]+)/gi)
      .map(cuteSentence)
      .join("") +
    "  🎀  " +
    esrever.reverse(bounds)
  );
};
export const cuteSentence = (sentence) => {
  return sentence
    .split(/([\s,]+)/gi)
    .map(cuteWord)
    .join("");
};
export const cuteWord = (word) => {
  //remember to handle exclamations, empty strings, commas etc. etc.!!
  if (word === "" || /[[!?.\s,]+]/gi.test(word)) return word;
  //TODO: random turn o's into flowers, turn quotes into unicode and ! and ?
  word = roundReplace(word);
  word = punctReplace(word);
  word = emojiReplace(word);
  return scriptify(word);
};
var qi = -1;
var qa = ["❝", "❞"];
export const quotes = () => {
  qi++;
  if (qi === 2) qi = 0;
  return qa[qi];
};
export const punctReplace = (word) => {
  return word
    .split("")
    .map(function (a) {
      if (a === "!") return randomElement(["❣", "❢"]);
      else if (a === "?") return randomElement(["¿", "?"]);
      else if (a === '"') return quotes();
      else return a;
    })
    .join("");
};
export const emojiReplace = (word) => {
  return word
    .replace(":)", randomElement(emoji[":)"]))
    .replace("(:", randomElement(emoji[":)"]))
    .replace("^^", randomElement(emoji["^^"]))
    .replace(":P", randomElement(emoji[":p"]))
    .replace(":p", randomElement(emoji[":p"]))
    .replace(":D", randomElement(emoji[":D"]))
    .replace("<3", randomElement(emoji["<3"]))
    .replace("(y)", randomElement(emoji["(y)"]))
    .replace("(Y)", randomElement(emoji["(y)"]));
};
export const roundReplace = (text) => {
  return text
    .split("")
    .map(function (a) {
      if (a.toLowerCase() === "o" || a === "0") {
        return randomElement(round);
      } else {
        return a;
      }
    })
    .join("");
};
var emoji = {
  ":)": [
    "٩(˘◡˘)۶",
    "(•◡•)",
    "(ɔ◔‿◔)ɔ",
    "❀◕‿◕❀",
    "(｡◕‿◕｡)",
    "●‿●",
    "◕‿◕",
    "😍",
    "ツ",
    "(✿ヘᴥヘ)",
    "🌘‿🌘",
    "(づ｡◕‿‿◕｡)づ",
    "(/◔◡◔)/",
    "s(^‿^)-b",
    "(人◕‿◕)",
    "(✿╹◡╹)",
    "◔◡◔",
  ],
  "^^": [
    "(^▽^)",
    "(✿^▽^)",
    "ᵔ⌣ᵔ",
    "ᵔᴥᵔ",
    "(≧◡≦)",
    "^ㅅ^",
    "^ㅂ^",
    "(⌒‿⌒)",
    "◠◡◠",
    "⁀‿⁀",
    "",
  ],
  ":p": [":Þ", ""],
  ":D": ["(ﾉ◕ヮ◕)ﾉ*:･ﾟ✧", "(✿◕ᗜ◕)━♫.*･｡ﾟ", "ᕕ(ᐛ)ᕗ"],
  "<3": [
    "♡",
    "❤",
    "♥",
    "❥",
    "💘",
    "💙",
    "💗",
    "💖",
    "💕",
    "💓",
    "💞",
    "💝",
    "💟",
  ],
  "(y)": ["👍", "👌"],
};
var round = [
  "❁",
  "🌺",
  "❀",
  "💮",
  "🏵",
  "🌸",
  "☯",
  "😍",
  "♡",
  "🍩",
  "🍬",
  "💙",
  "🌞",
  "💍",
  "🍪",
  "❤",
  "💗",
  "🍑",
  "💞",
];
var food = [
  "🍬",
  "🍭",
  "🍒",
  "🍎",
  "🍉",
  "🍇",
  "🍓",
  "🍌",
  "🍑",
  "🍰",
  "🎂",
  "🍩",
  "🍪",
  "🍧",
  "🍦",
  "🍫",
  "🍡",
];
var flowers = [
  "✿",
  "⚘",
  "❦",
  "❧",
  "✾",
  "❁",
  "❀",
  "💐",
  "🌸",
  "💮",
  "🏵",
  "🌹",
  "🌺",
  "🌻",
  "🌷",
  "☘",
  "🌱",
  "🌲",
  "🌳",
  "🌴",
  "🌿",
  "🍂",
];
var twinkles = [
  "⚛",
  "🌌",
  "🌠",
  "*",
  ":",
  "｡",
  "･ﾟ",
  "✧",
  "✮",
  "★",
  "✩",
  "⋆",
  ".",
  "°",
  "`",
  "✴",
  "｡",
  "✴",
  "⋆",
  "✳",
  "✶",
  "✷",
  "❈",
];
var animals = [
  "🐯",
  "🐎",
  "🐖",
  "🐷",
  "🐏",
  "🐑",
  "🐐",
  "🐫",
  "🐘",
  "🐭",
  "🐁",
  "🐀",
  "🐹",
  "🐰",
  "🐇",
  "🐿",
  "🐻",
  "🐨",
  "🐼",
  "🐾",
  "🐔",
  "🐓",
  "🐣",
  "🐤",
  "🐥",
  "🐦",
  "🐧",
  "🕊",
  "🐸",
  "🐢",
  "🐳",
  "🐋",
  "🐬",
  "🐟",
  "🐠",
  "🐡",
  "🐙",
  "🐚",
  "🐌",
  "🐞",
];
var plants = [
  "✿",
  "⚘",
  "❦",
  "❧",
  "✾",
  "❁",
  "❀",
  "💐",
  "🌸",
  "💮",
  "🏵",
  "🌹",
  "🌺",
  "🌻",
  "🌷",
  "☘",
  "🌱",
  "🌲",
  "🌳",
  "🌴",
  "🌿",
  "🍂",
];
var misc = [
  "🌌",
  "🌠",
  "🎉",
  "🎊",
  "🎈",
  "💌",
  "🎎",
  "🎁",
  "🎀",
  "🕯",
  "🔮",
  "🛀",
  "🎖",
  "🏆",
  "🏅",
  "👑",
  "💍",
  "👶",
  "👼",
  "👸",
  "👯",
  "👒",
  "👻",
];
var music = ["♫", "♬", "♪", "♩", "°", "｡", "✧", "🎻", "🎺", "🎸", "🎷", "📯"];
var flourish = [
  "•?((¯°·._.• ",
  "ıllıllı ",
  "¸,ø¤º°`°º¤ø,¸¸,ø¤º° ",
  "°°°·.°·..·°¯°·._.· ",
  "•´¯`•. ",
  "×º°”˜`”°º× ",
  "•]••´º´•» ",
  "]|I{•------» ",
  "§.•´¨'°÷•..× ",
  "•°¯`•• ",
  "(¯`·.¸¸.·´¯`·.¸¸.-> ",
  "*´¯`*.¸¸.*´¯`* ",
  "(¯`·.¸¸.-> °º ",
  "°·.¸.·°¯°·.¸.·°¯°·.¸.-> ",
  "•._.••´¯``•.¸¸.•` ",
  "¸„.-•~¹°”ˆ˜¨ ",
  "(¯´•._.• ",
  "••¤(`×",
  "•´¯`•» ",
  "`•.,¸¸,.•´¯ ",
  "¸,ø¤º°`°º¤ø,¸ ",
  ".o0×X×0o. ",
  ",-*'^'~*-.,_,.-*~ ",
  "`•.¸¸.•´´¯`••._.• ",
  "—(••÷",
  "¤¸¸.•´¯`•¸¸.•..>> ",
  "••.•´¯`•.•• ",
  ".•°¤*(¯`★´¯)*¤° ",
  "๑۞๑,¸¸,ø¤º°`°๑۩ ",
  "-漫~*'¨¯¨'*·舞~ ",
  "★·.·´¯`·.·★ ",
  "▁ ▂ ▄ ▅ ▆ ▇ █ ",
  "▀▄▀▄▀▄ ",
  "▌│█║▌║▌║ ",
];
export const boundingString = (n) => {
  return randomElement([
    foodString,
    twinkleString,
    animalString,
    flourishString,
  ])(n);
};
export const foodString = (n) => {
  return new Array(n + 1)
    .join("0")
    .split("")
    .map(function (a) {
      return randomElement(food);
    })
    .join(" ⋆ ");
};
export const twinkleString = (n) => {
  return new Array(n + 1)
    .join("0")
    .split("")
    .map(function () {
      return randomElement(twinkles);
    })
    .join("");
};
export const animalString = (n) => {
  return new Array(n + 1)
    .join("0")
    .split("")
    .map(function (a) {
      return randomElement(animals);
    })
    .join(" ⋆ ");
};
export const flourishString = (n) => {
  return randomElement(flourish);
};
export const scriptify = (text) => {
  var map = {
    0: "𝟢",
    1: "𝟣",
    2: "𝟤",
    3: "𝟥",
    4: "𝟦",
    5: "𝟧",
    6: "𝟨",
    7: "𝟩",
    8: "𝟪",
    9: "𝟫",
    a: "𝒶",
    b: "𝒷",
    c: "𝒸",
    d: "𝒹",
    e: "𝑒",
    f: "𝒻",
    g: "𝑔",
    h: "𝒽",
    i: "𝒾",
    j: "𝒿",
    k: "𝓀",
    l: "𝓁",
    m: "𝓂",
    n: "𝓃",
    o: "𝑜",
    p: "𝓅",
    q: "𝓆",
    r: "𝓇",
    s: "𝓈",
    t: "𝓉",
    u: "𝓊",
    v: "𝓋",
    w: "𝓌",
    x: "𝓍",
    y: "𝓎",
    z: "𝓏",
    A: "𝒜",
    B: "𝐵",
    C: "𝒞",
    D: "𝒟",
    E: "𝐸",
    F: "𝐹",
    G: "𝒢",
    H: "𝐻",
    I: "𝐼",
    J: "𝒥",
    K: "𝒦",
    L: "𝐿",
    M: "𝑀",
    N: "𝒩",
    O: "𝒪",
    P: "𝒫",
    Q: "𝒬",
    R: "𝑅",
    S: "𝒮",
    T: "𝒯",
    U: "𝒰",
    V: "𝒱",
    W: "𝒲",
    X: "𝒳",
    Y: "𝒴",
    Z: "𝒵",
  };
  var charArray = text.split("");
  for (var i = 0; i < charArray.length; i++) {
    if (map[charArray[i].toLowerCase()]) {
      charArray[i] = map[charArray[i]];
    }
  }
  text = charArray.join("");
  return text;
};
export const shuffleArray = (array) => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
};
export const randomElement = (a) => {
  return a[Math.floor(Math.random() * a.length)];
};
//https://github.com/mathiasbynens/esrever
!(function (e) {
  var o = "object" == typeof exports && exports,
    r = "object" == typeof module && module && module.exports == o && module,
    n = "object" == typeof global && global;
  (n.global === n || n.window === n) && (e = n);
  var t = /(<%= allExceptCombiningMarks %>)(<%= combiningMarks %>+)/g,
    i = /([\uD800-\uDBFF])([\uDC00-\uDFFF])/g,
    f = function (e) {
      e = e
        .replace(t, function (e, o, r) {
          return f(r) + o;
        })
        .replace(i, "$2$1");
      for (var o = "", r = e.length; r--; ) o += e.charAt(r);
      return o;
    },
    l = { version: "<%= version %>", reverse: f };
  if (
    "export function" == typeof define &&
    "object" == typeof define.amd &&
    define.amd
  )
    define(function () {
      return l;
    });
  else if (o && !o.nodeType)
    if (r) r.exports = l;
    else for (var a in l) l.hasOwnProperty(a) && (o[a] = l[a]);
  else e.esrever = l;
})(this);

// CRAZY TEXT
export const fullCrazy = (text) => {
  if (text.trim() === "") return "";
  return randomSymbols(2) + "  " + crazifyText(text) + "  " + randomSymbols(2);
};
export const crazifyText = (text) => {
  text = text.split("");
  for (var i = 0; i < text.length; i++) {
    text[i] = crazifyCharacter(text[i]);
  }
  return text.join("");
};
export const crazifyCharacter = (c) => {
  c = c.toLowerCase();
  var map = {
    "&": "⅋",
    "%": ["⅍", "℀", "℁", "℆", "℅"],
    0: ["０", "Ѳ", "ʘ"],
    1: ["➀", "❶", "１"],
    2: ["２", "❷", "➁"],
    3: ["３", "❸", "➂"],
    4: ["４", "❹", "➃"],
    5: ["❺", "➄", "５"],
    6: ["６", "❻", "➅"],
    7: ["７", "❼", "➆"],
    8: ["８", "➇", "❽"],
    9: ["➈", "❾", "９"],
    "<": [
      "≼",
      "≺",
      "≪",
      "☾",
      "≾",
      "⋜",
      "⋞",
      "⋐",
      "⊂",
      "⊏",
      "⊑",
      "《",
      "＜",
      "❮",
      "❰",
      "⫷",
    ],
    ">": "☽≫≻≽≿⋝⋟⋑⊃⊐⊒⫸》＞❯❱",
    "[": "【〖〘〚［",
    "]": "】〗〙〛］",
    "*": "✨✩✪✫✬✭✮✯✰✦✱✲✳✴✵✶✷֍֎✸✹✺✻✼✽✾✿❀❁❂❃❄★☆＊",
    a: [
      "Ⓐ",
      "ⓐ",
      "α",
      "Ａ",
      "ａ",
      "ᗩ",
      "卂",
      "Δ",
      "ค",
      "α",
      "ά",
      "Ã",
      "𝔞",
      "𝓪",
      "𝒶",
      "𝓐",
      "𝐀",
      "𝐚",
      "𝔸",
      "𝕒",
      "ᵃ",
    ],
    b: [
      "Ⓑ",
      "ⓑ",
      "в",
      "Ｂ",
      "乃",
      "ｂ",
      "ᗷ",
      "β",
      "๒",
      "в",
      "в",
      "β",
      "𝔟",
      "𝓫",
      "𝒷",
      "𝓑",
      "𝐁",
      "𝐛",
      "𝔹",
      "𝕓",
      "ᵇ",
    ],
    c: [
      "Ⓒ",
      "ⓒ",
      "匚",
      "¢",
      "Ｃ",
      "ｃ",
      "ᑕ",
      "Ć",
      "ς",
      "c",
      "ς",
      "Č",
      "℃",
      "𝔠",
      "𝓬",
      "𝒸",
      "𝓒",
      "𝐂",
      "𝐜",
      "ℂ",
      "𝕔",
      "ᶜ",
    ],
    d: [
      "Ⓓ",
      "ⓓ",
      "∂",
      "Ｄ",
      "ｄ",
      "ᗪ",
      "Đ",
      "๔",
      "∂",
      "đ",
      "Ď",
      "𝔡",
      "𝓭",
      "𝒹",
      "𝓓",
      "𝐃",
      "ᗪ",
      "𝐝",
      "𝔻",
      "𝕕",
      "ᵈ",
    ],
    e: [
      "Ⓔ",
      "乇",
      "ⓔ",
      "є",
      "Ｅ",
      "ｅ",
      "ᗴ",
      "€",
      "є",
      "ε",
      "έ",
      "Ẹ",
      "𝔢",
      "𝒆",
      "𝑒",
      "𝓔",
      "𝐄",
      "𝐞",
      "𝔼",
      "𝕖",
      "ᵉ",
    ],
    f: [
      "Ⓕ",
      "ⓕ",
      "ƒ",
      "Ｆ",
      "ｆ",
      "千",
      "ᖴ",
      "ℱ",
      "Ŧ",
      "ғ",
      "ғ",
      "Ƒ",
      "𝔣",
      "𝒇",
      "𝒻",
      "𝓕",
      "𝐅",
      "𝐟",
      "𝔽",
      "𝕗",
      "ᶠ",
    ],
    g: [
      "Ⓖ",
      "ⓖ",
      "ق",
      "g",
      "Ｇ",
      "ｇ",
      "Ǥ",
      "Ꮆ",
      "ﻮ",
      "g",
      "ģ",
      "Ğ",
      "𝔤",
      "𝓰",
      "𝑔",
      "𝓖",
      "𝐆",
      "𝐠",
      "𝔾",
      "𝕘",
      "ᵍ",
      "Ꮆ",
    ],
    h: [
      "Ⓗ",
      "卄",
      "ⓗ",
      "н",
      "Ｈ",
      "ｈ",
      "ᕼ",
      "Ħ",
      "ђ",
      "н",
      "ħ",
      "Ĥ",
      "𝔥",
      "𝓱",
      "𝒽",
      "𝓗",
      "𝐇",
      "𝐡",
      "ℍ",
      "𝕙",
      "ʰ",
    ],
    i: [
      "Ⓘ",
      "ⓘ",
      "ι",
      "Ｉ",
      "ｉ",
      "Ꭵ",
      "丨",
      "Ɨ",
      "เ",
      "ι",
      "ί",
      "Į",
      "𝔦",
      "𝓲",
      "𝒾",
      "𝓘",
      "𝐈",
      "𝐢",
      "𝕀",
      "𝕚",
      "ᶤ",
    ],
    j: [
      "Ⓙ",
      "ⓙ",
      "נ",
      "Ｊ",
      "ڶ",
      "ｊ",
      "ᒎ",
      "Ĵ",
      "ן",
      "נ",
      "ј",
      "Ĵ",
      "𝔧",
      "𝓳",
      "𝒿",
      "𝓙",
      "𝐉",
      "𝐣",
      "𝕁",
      "𝕛",
      "ʲ",
    ],
    k: [
      "Ⓚ",
      "ⓚ",
      "к",
      "Ｋ",
      "ｋ",
      "ᛕ",
      "Ҝ",
      "к",
      "к",
      "ķ",
      "Ќ",
      "𝔨",
      "𝓴",
      "𝓀",
      "𝓚",
      "𝐊",
      "𝐤",
      "𝕂",
      "𝕜",
      "ᵏ",
      "Ҝ",
    ],
    l: [
      "Ⓛ",
      "ⓛ",
      "ℓ",
      "ㄥ",
      "Ｌ",
      "ｌ",
      "ᒪ",
      "Ł",
      "l",
      "ℓ",
      "Ļ",
      "Ĺ",
      "𝔩",
      "𝓵",
      "𝓁",
      "𝓛",
      "𝐋",
      "𝐥",
      "𝕃",
      "𝕝",
      "ˡ",
    ],
    m: [
      "Ⓜ",
      "ⓜ",
      "м",
      "Ｍ",
      "ｍ",
      "ᗰ",
      "Μ",
      "๓",
      "м",
      "м",
      "ϻ",
      "𝔪",
      "𝓶",
      "𝓂",
      "𝓜",
      "𝐌",
      "𝐦",
      "𝕄",
      "𝕞",
      "ᵐ",
      "爪",
    ],
    n: [
      "Ⓝ",
      "几",
      "ⓝ",
      "η",
      "Ｎ",
      "ｎ",
      "ᑎ",
      "Ň",
      "ภ",
      "η",
      "ή",
      "Ň",
      "𝔫",
      "𝓷",
      "𝓃",
      "𝓝",
      "𝐍",
      "𝐧",
      "ℕ",
      "𝕟",
      "ᶰ",
    ],
    o: [
      "Ⓞ",
      "ㄖ",
      "ⓞ",
      "σ",
      "Ｏ",
      "ｏ",
      "ᗝ",
      "Ø",
      "๏",
      "σ",
      "ό",
      "Ỗ",
      "𝔬",
      "𝓸",
      "𝑜",
      "𝓞",
      "𝐎",
      "𝐨",
      "𝕆",
      "𝕠",
      "ᵒ",
    ],
    p: [
      "Ⓟ",
      "ⓟ",
      "ρ",
      "Ｐ",
      "ｐ",
      "卩",
      "ᑭ",
      "Ƥ",
      "ק",
      "ρ",
      "ρ",
      "Ƥ",
      "𝔭",
      "𝓹",
      "𝓅",
      "𝓟",
      "𝐏",
      "𝐩",
      "ℙ",
      "𝕡",
      "ᵖ",
    ],
    q: [
      "Ⓠ",
      "ⓠ",
      "q",
      "Ｑ",
      "ｑ",
      "Ɋ",
      "Ω",
      "ợ",
      "q",
      "q",
      "Ǫ",
      "𝔮",
      "𝓺",
      "𝓆",
      "𝓠",
      "𝐐",
      "𝐪",
      "ℚ",
      "𝕢",
      "ᵠ",
    ],
    r: [
      "Ⓡ",
      "ⓡ",
      "я",
      "尺",
      "Ｒ",
      "ｒ",
      "ᖇ",
      "Ř",
      "г",
      "я",
      "ŕ",
      "Ř",
      "𝔯",
      "𝓻",
      "𝓇",
      "𝓡",
      "𝐑",
      "𝐫",
      "ℝ",
      "𝕣",
      "ʳ",
    ],
    s: [
      "Ⓢ",
      "ⓢ",
      "ѕ",
      "Ｓ",
      "丂",
      "ｓ",
      "ᔕ",
      "Ş",
      "ร",
      "s",
      "ş",
      "Ŝ",
      "𝔰",
      "𝓼",
      "𝓈",
      "𝓢",
      "𝐒",
      "𝐬",
      "𝕊",
      "𝕤",
      "ˢ",
    ],
    t: [
      "Ⓣ",
      "ⓣ",
      "т",
      "Ｔ",
      "ｔ",
      "丅",
      "Ŧ",
      "t",
      "т",
      "ţ",
      "Ť",
      "𝔱",
      "𝓽",
      "𝓉",
      "𝓣",
      "𝐓",
      "𝐭",
      "𝕋",
      "𝕥",
      "ᵗ",
    ],
    u: [
      "Ⓤ",
      "ⓤ",
      "υ",
      "Ｕ",
      "ｕ",
      "ᑌ",
      "Ữ",
      "ย",
      "υ",
      "ù",
      "Ǘ",
      "𝔲",
      "𝓾",
      "𝓊",
      "𝓤",
      "𝐔",
      "𝐮",
      "𝕌",
      "𝕦",
      "ᵘ",
    ],
    v: [
      "Ⓥ",
      "ⓥ",
      "ν",
      "Ｖ",
      "ｖ",
      "ᐯ",
      "V",
      "ש",
      "v",
      "ν",
      "Ѷ",
      "𝔳",
      "𝓿",
      "𝓋",
      "𝓥",
      "𝐕",
      "𝐯",
      "𝕍",
      "𝕧",
      "ᵛ",
    ],
    w: [
      "Ⓦ",
      "ⓦ",
      "ω",
      "Ｗ",
      "ｗ",
      "ᗯ",
      "Ŵ",
      "ฬ",
      "ω",
      "ώ",
      "Ŵ",
      "𝔴",
      "𝔀",
      "𝓌",
      "𝓦",
      "𝐖",
      "𝐰",
      "𝕎",
      "𝕨",
      "ʷ",
      "山",
    ],
    x: [
      "Ⓧ",
      "ⓧ",
      "χ",
      "Ｘ",
      "乂",
      "ｘ",
      "᙭",
      "Ж",
      "א",
      "x",
      "x",
      "Ж",
      "𝔵",
      "𝔁",
      "𝓍",
      "𝓧",
      "𝐗",
      "𝐱",
      "𝕏",
      "𝕩",
      "ˣ",
    ],
    y: [
      "Ⓨ",
      "ㄚ",
      "ⓨ",
      "у",
      "Ｙ",
      "ｙ",
      "Ƴ",
      "¥",
      "ץ",
      "ү",
      "ч",
      "Ў",
      "𝔶",
      "𝔂",
      "𝓎",
      "𝓨",
      "𝐘",
      "𝐲",
      "𝕐",
      "𝕪",
      "ʸ",
    ],
    z: [
      "Ⓩ",
      "ⓩ",
      "z",
      "乙",
      "Ｚ",
      "ｚ",
      "Ƶ",
      "Ž",
      "z",
      "z",
      "ž",
      "Ż",
      "𝔷",
      "𝔃",
      "𝓏",
      "𝓩",
      "𝐙",
      "𝐳",
      "ℤ",
      "𝕫",
      "ᶻ",
    ],
  };
  if (map[c]) {
    return randomElement(map[c]);
  } else {
    return c;
  }
};
export const randomSymbols = (n) => {
  var symbols = [
    "🐙",
    "🐉",
    "🐊",
    "🐒",
    "🐝",
    "🐜",
    "🐚",
    "🐲",
    "🐳",
    "🐸",
    "👑",
    "👹",
    "👺",
    "👤",
    "💲",
    "💣",
    "💙",
    "💚",
    "💛",
    "💜",
    "💝",
    "💗",
    "💘",
    "💞",
    "💔",
    "💥",
    "🐯",
    "🐼",
    "🐻",
    "🐺",
    "👌",
    "🐍",
    "🐧",
    "🐟",
    "🐠",
    "🐨",
    "🎯",
    "🏆",
    "🎁",
    "🎀",
    "🎉",
    "🎈",
    "🍮",
    "🍭",
    "🍬",
    "🍫",
    "🍪",
    "🍧",
    "🌷",
    "🍓",
    "😺",
    "😾",
    "✎",
    "😎",
    "😝",
    "😂",
    "😈",
    "😡",
    "😲",
    "😳",
    "🍔",
    "🍟",
    "🍩",
    "🎃",
    "🎄",
    "🎅",
    "🐣",
    "🐤",
    "👍",
    "👊",
    "👻",
    "👽",
    "👮",
    "💎",
    "💋",
    "👣",
    "💀",
    "💢",
    "🔥",
    "♔",
    "♕",
    "♖",
    "♗",
    "♘",
    "♙",
    "♚",
    "♛",
    "♜",
    "♝",
    "♞",
    "♟",
    "♠",
    "♡",
    "♢",
    "♣",
    "♤",
    "♥",
    "♦",
    "♧",
    "♨",
    "♩",
    "♪",
    "♬",
    "★",
    "☆",
    "☺",
    "☹",
    "☯",
    "☮",
    "☢",
    "☠",
    "☟",
    "☞",
    "☝",
    "☜",
    "✌",
    "✋",
    "✊",
    "⛵",
    "ൠ",
    "✌",
    "ඏ",
  ];
  var s = [];
  for (var i = 0; i < n; i++) s.push(randomElement(symbols));
  return s.join("");
};
export const randInt = (min, max) => {
  return min + Math.floor(Math.random() * (max - min + 1));
};

try {
  const getScrollbarWidth = () => {
    return window.innerWidth - document.documentElement.clientWidth;
  };
  var instaFontsButton = document.createElement("button");
  instaFontsButton.innerHTML = "Design Your Own Font";
  instaFontsButton.style.cssText = `position:absolute; bottom:0.3rem; right:${
    getScrollbarWidth() > 10 ? "1.2" : "0.3"
  }rem;`;
  instaFontsButton.onclick = function () {
    window.open("https://instafonts.io/create");
  };
  document
    .querySelector("#ghetto-text")
    .parentElement.appendChild(instaFontsButton);
} catch (e) {}

export const reverseIsDisabled = true;

try {
  var jsonData = {
    phrases1: "",
    phrases2: "",
    words1: "",
    words2: "",
    intraword1: "",
    intraword2: "",
    prefixes1: "",
    prefixes2: "",
    suffixes1: "",
    suffixes2: "",
    regex1: "",
    regex2: "",
    rev_regex1: "",
    rev_regex2: "",
    ordering1: "",
    ordering2: "",
  };
  var phrases1 = jsonData.phrases1.split("\n");
  var phrases2 = jsonData.phrases2.split("\n");
  var words1 = jsonData.words1.split("\n");
  var words2 = jsonData.words2.split("\n");
  var intraword1 = jsonData.intraword1.split("\n");
  var intraword2 = jsonData.intraword2.split("\n");
  var prefixes1 = jsonData.prefixes1.split("\n");
  var prefixes2 = jsonData.prefixes2.split("\n");
  var suffixes1 = jsonData.suffixes1.split("\n");
  var vsuffixes2 = jsonData.suffixes2.split("\n");
  var regex1 = jsonData.regex1.split("\n");
  var regex2 = jsonData.regex2.split("\n");
  var rev_regex1 = jsonData.rev_regex1.split("\n");
  var rev_regex2 = jsonData.rev_regex2.split("\n");
  var ordering1 = jsonData.ordering1.split("\n");
  var ordering2 = jsonData.ordering2.split("\n");
} catch (err) {
  alert(
    "Ahh an error! Please contact me via the contact form and I'll fix it asap. Error log: " +
      err.message
  );
}

//fix for mysql trailing newline deletion problem
export const evenUpSizes = (a, b) => {
  if (a.length > b.length) {
    while (a.length > b.length) b.push("");
  } else if (b.length > a.length) {
    while (b.length > a.length) a.push("");
  }
};
