import React from "react";
export default class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div
        className="col-lg-6 col-md-12 col-sm-12 col-12 mx-auto"
        style={{
          zIndex: 1,
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "space-between",
          paddingTop: 21,
          paddingLeft: 13,
          paddingRight: 13,
        }}
      >
        <h4>AskMe - PRIVACY POLICY</h4>
        <p style={{ color: "#000", fontWeight: "500" }}>
          Last updated August 21, 2022
          <br />
          Thank you for choosing to be part of our community at AskMe
          ("Company", "we", "us", "our"). We are committed to protecting your
          personal information and your right to privacy. If you have any
          questions or concerns about this privacy notice, or our practices with
          regards to your personal information, please contact us at
          overflozz@gmail.com.
          <br />
          When you use our mobile application, as the case may be (the "App")
          and more generally, use any of our services (the "Services", which
          include the App), we appreciate that you are trusting us with your
          personal information. We take your privacy very seriously. In this
          privacy notice, we seek to explain to you in the clearest way possible
          what information we collect, how we use it and what rights you have in
          relation to it. We hope you take some time to read through it
          carefully, as it is important. If there are any terms in this privacy
          notice that you do not agree with, please discontinue use of our
          Services immediately.
          <br />
          <br />
          This privacy notice applies to all information collected through our
          Services (which, as described above, includes our App), as well as,
          any related services, sales, marketing or events.
          <br />
          <br />
          Please read this privacy notice carefully as it will help you
          understand what we do with the information that we collect.
          <br />
          <br />
          TABLE OF CONTENTS
          <br />
          <br />
          1. WHAT INFORMATION DO WE COLLECT?
          <br />
          2. HOW DO WE USE YOUR INFORMATION?
          <br />
          3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
          <br />
          4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          <br />
          5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
          <br />
          6. HOW LONG DO WE KEEP YOUR INFORMATION?
          <br />
          7. HOW DO WE KEEP YOUR INFORMATION SAFE?
          <br />
          8. WHAT ARE YOUR PRIVACY RIGHTS?
          <br />
          9. CONTROLS FOR DO-NOT-TRACK FEATURES
          <br />
          10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          <br />
          11. DO WE MAKE UPDATES TO THIS NOTICE?
          <br />
          12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          <br />
          13. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?
          <br />
          <br />
          1. WHAT INFORMATION DO WE COLLECT?
          <br />
          <br />
          Personal information you disclose to us
          <br />
          <br />
          In Short: We collect personal information that you provide to us.
          <br />
          <br />
          We collect personal information that you voluntarily provide to us
          when you register on the App, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the App (such as by posting messages in
          our online forums or entering competitions, contests or giveaways) or
          otherwise when you contact us.
          <br />
          <br />
          The personal information that we collect depends on the context of
          your interactions with us and the App, the choices you make and the
          products and features you use. The personal information we collect may
          include the following:
          <br />
          <br />
          Personal Information Provided by You. We collect usernames; and other
          similar information.
          <br />
          <br />
          Social Media Login Data. We may provide you with the option to
          register with us using your existing social media account details,
          like your Facebook, Twitter or other social media account. If you
          choose to register in this way, we will collect the Information
          described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS"
          below.
          <br />
          <br />
          All personal information that you provide to us must be true, complete
          and accurate, and you must notify us of any changes to such personal
          information.
          <br />
          <br />
          2. HOW DO WE USE YOUR INFORMATION?
          <br />
          <br />
          In Short: We process your information for purposes based on legitimate
          business interests, the fulfillment of our contract with you,
          compliance with our legal obligations, and/or your consent.
          <br />
          <br />
          We use personal information collected via our App for a variety of
          business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations. We
          indicate the specific processing grounds we rely on next to each
          purpose listed below.
          <br />
          <br />
          We use the information we collect or receive:
          <br />
          To facilitate account creation and logon process. If you choose to
          link your account with us to a third-party account (such as your
          Google or Facebook account), we use the information you allowed us to
          collect from those third parties to facilitate account creation and
          logon process for the performance of the contract. See the section
          below headed "HOW DO WE HANDLE YOUR SOCIAL LOGINS" for further
          information.
          <br />
          To post testimonials. We post testimonials on our App that may contain
          personal information. Prior to posting a testimonial, we will obtain
          your consent to use your name and the content of the testimonial. If
          you wish to update, or delete your testimonial, please contact us at
          overflozz@gmail.com and be sure to include your name, testimonial
          location, and contact information.
          <br />
          Request feedback. We may use your information to request feedback and
          to contact you about your use of our App.
          <br />
          To enable user-to-user communications. We may use your information in
          order to enable user-to-user communications with each user's consent.
          <br />
          To manage user accounts. We may use your information for the purposes
          of managing our account and keeping it in working order.
          <br />
          <br />
          <br />
          <br />
          <br />
          To send you marketing and promotional communications. We and/or our
          third-party marketing partners may use the personal information you
          send to us for our marketing purposes, if this is in accordance with
          your marketing preferences. For example, when expressing an interest
          in obtaining information about us or our App, subscribing to marketing
          or otherwise contacting us, we will collect personal information from
          you. You can opt-out of our marketing emails at any time (see the
          "WHAT ARE YOUR PRIVACY RIGHTS" below).
          <br />
          Deliver targeted advertising to you. We may use your information to
          develop and display personalized content and advertising (and work
          with third parties who do so) tailored to your interests and/or
          location and to measure its effectiveness.
          <br />
          <br />
          3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
          <br />
          <br />
          In Short: We only share information with your consent, to comply with
          laws, to provide you with services, to protect your rights, or to
          fulfill business obligations.
          <br />
          <br />
          We may process or share your data that we hold based on the following
          legal basis:
          <br />
          Consent: We may process your data if you have given us specific
          consent to use your personal information for a specific purpose.
          <br />
          Legitimate Interests: We may process your data when it is reasonably
          necessary to achieve our legitimate business interests.
          <br />
          Performance of a Contract: Where we have entered into a contract with
          you, we may process your personal information to fulfill the terms of
          our contract.
          <br />
          Legal Obligations: We may disclose your information where we are
          legally required to do so in order to comply with applicable law,
          governmental requests, a judicial proceeding, court order, or legal
          process, such as in response to a court order or a subpoena (including
          in response to public authorities to meet national security or law
          enforcement requirements).
          <br />
          Vital Interests: We may disclose your information where we believe it
          is necessary to investigate, prevent, or take action regarding
          potential violations of our policies, suspected fraud, situations
          involving potential threats to the safety of any person and illegal
          activities, or as evidence in litigation in which we are involved.
          <br />
          More specifically, we may need to process your data or share your
          personal information in the following situations:
          <br />
          Business Transfers. We may share or transfer your information in
          connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
          <br />
          Other Users. When you share personal information (for example, by
          posting comments, contributions or other content to the App) or
          otherwise interact with public areas of the App, such personal
          information may be viewed by all users and may be publicly made
          available outside the App in perpetuity. If you interact with other
          users of our App and register for our App through a social network
          (such as Facebook), your contacts on the social network will see your
          name, profile photo, and descriptions of your activity. Similarly,
          other users will be able to view descriptions of your activity,
          communicate with you within our App, and view your profile.
          <br />
          Offer Wall. Our App may display a third-party hosted "offer wall."
          Such an offer wall allows third-party advertisers to offer virtual
          currency, gifts, or other items to users in return for the acceptance
          and completion of an advertisement offer. Such an offer wall may
          appear in our App and be displayed to you based on certain data, such
          as your geographic area or demographic information. When you click on
          an offer wall, you will be brought to an external website belonging to
          other persons and will leave our App. A unique identifier, such as
          your user ID, will be shared with the offer wall provider in order to
          prevent fraud and properly credit your account with the relevant
          reward. Please note that we do not control third-party websites and
          have no responsibility in relation to the content of such websites.
          The inclusion of a link towards a third-party website does not imply
          an endorsement by us of such website. Accordingly, we do not make any
          warranty regarding such third-party websites and we will not be liable
          for any loss or damage caused by the use of such websites. In
          addition, when you access any third-party website, please understand
          that your rights while accessing and using those websites will be
          governed by the privacy notice and terms of service relating to the
          use of those websites.
          <br />
          <br />
          4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          <br />
          <br />
          In Short: We may use cookies and other tracking technologies to
          collect and store your information.
          <br />
          <br />
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
          <br />
          <br />
          5. HOW DO WE HANDLE YOUR SOCIAL LOGINS? <br />
          <br />
          In Short: If you choose to register or log in to our services using a
          social media account, we may have access to certain information about
          you.
          <br />
          <br />
          Our App offers you the ability to register and login using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile Information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, profile picture as well as other information you choose
          to make public on such social media platform.
          <br />
          <br />
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant App. Please note that we do not control, and are
          not responsible for, other uses of your personal information by your
          third-party social media provider. We recommend that you review their
          privacy notice to understand how they collect, use and share your
          personal information, and how you can set your privacy preferences on
          their sites and apps.
          <br />
          <br />
          6. HOW LONG DO WE KEEP YOUR INFORMATION?
          <br />
          <br />
          In Short: We keep your information for as long as necessary to fulfill
          the purposes outlined in this privacy notice unless otherwise required
          by law.
          <br />
          <br />
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
          <br />
          <br />
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
          <br />
          <br />
          7. HOW DO WE KEEP YOUR INFORMATION SAFE?
          <br />
          <br />
          In Short: We aim to protect your personal information through a system
          of organizational and technical security measures.
          <br />
          <br />
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. However, despite our safeguards and efforts to secure your
          information, no electronic transmission over the Internet or
          information storage technology can be guaranteed to be 100% secure, so
          we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorized third parties will not be able to defeat our security,
          and improperly collect, access, steal, or modify your information.
          Although we will do our best to protect your personal information,
          transmission of personal information to and from our App is at your
          own risk. You should only access the App within a secure environment.
          <br />
          <br />
          8. WHAT ARE YOUR PRIVACY RIGHTS?
          <br />
          <br />
          In Short: In some regions, such as the European Economic Area, you
          have rights that allow you greater access to and control over your
          personal information. You may review, change, or terminate your
          account at any time.
          <br />
          <br />
          In some regions (like the European Economic Area), you have certain
          rights under applicable data protection laws. These may include the
          right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your personal information; and (iv) if
          applicable, to data portability. In certain circumstances, you may
          also have the right to object to the processing of your personal
          information. To make such a request, please use the contact details
          provided below. We will consider and act upon any request in
          accordance with applicable data protection laws.
          <br />
          <br />
          If we are relying on your consent to process your personal
          information, you have the right to withdraw your consent at any time.
          Please note however that this will not affect the lawfulness of the
          processing before its withdrawal, nor will it affect the processing of
          your personal information conducted in reliance on lawful processing
          grounds other than consent.
          <br /> <br />
          If you are a resident in the European Economic Area and you believe we
          are unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority.
          You can find their contact details here:
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
          <br />
          <br />
          If you are a resident in Switzerland, the contact details for the data
          protection authorities are available here:
          https://www.edoeb.admin.ch/edoeb/en/home.html.
          <br />
          <br />
          If you have questions or comments about your privacy rights, you may
          email us at overflozz@gmail.com.
          <br />
          <br />
          Account Information
          <br />
          <br />
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
          <br />
          Log in to your account settings and update your user account.
          <br />
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          Terms of Use and/or comply with applicable legal requirements.
          <br />
          <br />
          Cookies and similar technologies: Most Web browsers are set to accept
          cookies by default. If you prefer, you can usually choose to set your
          browser to remove cookies and to reject cookies. If you choose to
          remove cookies or reject cookies, this could affect certain features
          or services of our App. To opt-out of interest-based advertising by
          advertisers on our App visit http://www.aboutads.info/choices/.
          <br />
          <br />
          Opting out of email marketing: You can unsubscribe from our marketing
          email list at any time by clicking on the unsubscribe link in the
          emails that we send or by contacting us using the details provided
          below. You will then be removed from the marketing email list —
          however, we may still communicate with you, for example to send you
          service-related emails that are necessary for the administration and
          use of your account, to respond to service requests, or for other
          non-marketing purposes. To otherwise opt-out, you may:
          <br />
          Access your account settings and update your preferences.
          <br />
          <br />
          9. CONTROLS FOR DO-NOT-TRACK FEATURES
          <br />
          <br />
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice. <br />
          <br />
          10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          <br />
          <br />
          In Short: Yes, if you are a resident of California, you are granted
          specific rights regarding access to your personal information.
          <br />
          <br />
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
          <br />
          <br />
          If you are under 18 years of age, reside in California, and have a
          registered account with the App, you have the right to request removal
          of unwanted data that you publicly post on the App. To request removal
          of such data, please contact us using the contact information provided
          below, and include the email address associated with your account and
          a statement that you reside in California. We will make sure the data
          is not publicly displayed on the App, but please be aware that the
          data may not be completely or comprehensively removed from all our
          systems (e.g. backups, etc.).
          <br />
          <br />
          11. DO WE MAKE UPDATES TO THIS NOTICE? <br />
          <br />
          In Short: Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
          <br />
          <br />
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
          <br />
          <br />
          12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? <br />
          <br />
          If you have questions or comments about this notice, you may email us
          at overflozz@gmail.com or by post to:
          <br />
          <br />
          AskMe
          <br />
          112 place de l eglise
          <br />
          Berat 31370
          <br />
          France
          <br />
          <br />
          13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU? <br />
          <br />
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it in some circumstances. To
          request to review, update, or delete your personal information, please
          visit: __________. We will respond to your request within 30 days.
          <br />
        </p>
      </div>
    );
  }
}
