import "./App.css";
import React from "react";
import { withParams } from "./hocs";
import Header from "./Header";
import Footer from "./Footer";

import "./scss/swipe.scss";
class Landing extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <Header /> */}
        <main className="test">
          <section
            className="section section-header text-dark "
            style={{
              marginBottom: 0,
              marginTop: 0,
              //background: "linear-gradient(311deg, rgba(254,49,157,1) 0%, rgba(254,93,120,1) 49%, rgba(249,109,62,1) 100%)",
              background: "#000",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10 text-center mb-5 mb-md-7">
                  <img
                    className="d-none d-lg-inline-block shadow"
                    src="./assets/logo216.jpg"
                    alt="Mobile App Illustration"
                    style={{ borderRadius: 10, height: 50, marginBottom: 21 }}
                  />
                  <h1
                    className="display-2 font-weight-bolder mb-4"
                    style={{ color: "#fff" }}
                  >
                    Anonymous Q&A
                    <br /> on Snapchat and Instagram
                  </h1>
                  <p
                    className="lead mb-4 mb-lg-5"
                    style={{ color: "rgba(255,255,255,0.7)" }}
                  >
                    AskMe lets you create a link where you friend can share
                    anonymous messages with you. <br></br>Currently only
                    available on iOS for free.
                  </p>
                  <div>
                    <a
                      href="https://apps.apple.com/us/app/askme-anonymous-q-a/id1640939005"
                      className="btn btn-light btn-download-app mb-xl-0 mr-2 mr-md-3"
                      style={{ color: "#000", backgroundColor: "#fff" }}
                    >
                      <span className="d-flex align-items-center">
                        <span className="icon icon-brand mr-2 mr-md-3">
                          <span className="fab fa-apple"></span>
                        </span>
                        <span className="d-inline-block text-left">
                          <small className="font-weight-normal d-none d-md-block">
                            Available on
                          </small>{" "}
                          App Store
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="section bg-soft" id="download">
            <figure className="position-absolute top-0 left-0 w-100 d-none d-md-block mt-n3"></figure>
            <div className="container">
              <div className="row row-grid align-items-center">
                <div className="col-12 col-lg-6">
                  <span className="h5 text-muted mb-2 d-block">
                    Download App
                  </span>
                  <h2 className="display-3 mb-4">Get started in seconds</h2>
                  <p className="lead text-muted">
                    Access our customization tools with thousands of icons and
                    our profile page builder in a matter of seconds. You don't
                    even need to create an account.
                  </p>
                  <div className="mt-4 mt-lg-5">
                    <a
                      href="https://apps.apple.com/us/app/askme-anonymous-q-a/id1640939005"
                      className="btn btn-dark btn-download-app mb-xl-0 mr-2 mr-md-3"
                    >
                      <span className="d-flex align-items-center">
                        <span className="icon icon-brand mr-2 mr-md-3">
                          <span className="fab fa-apple"></span>
                        </span>
                        <span className="d-inline-block text-left">
                          <small className="font-weight-normal d-none d-md-block">
                            Available on
                          </small>{" "}
                          App Store
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-12 col-lg-5 ml-lg-auto">
                  <img
                    className="d-none d-lg-inline-block shadow"
                    src="./assets/img/illustrations/screenshot_app.png"
                    alt="Mobile App Illustration"
                    style={{ borderRadius: 50, height: "90vh" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withParams(Landing);
