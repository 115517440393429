export const COLORS = {
  primary: "#3366FF",
  secondary: "#FF8499",
  grey_1: "#F8F9FA",
  grey_2: "#DDE2E5",
  grey_3: "#ACB5BD",
  grey_4: "#495057",
  grey_5: "#212429",
  green: "#42B5A3",
  red: "#F57799",
};
