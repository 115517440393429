import React from "react";
import axios from "axios";
import { withParams } from "./hocs";
import { COLORS } from "./_style/colors";
import Lottie from "react-lottie-player";
import * as loadingLottie from "./lottie/loading-animation-gradient-line.json";
import * as notFoundLottie from "./lottie/10687-not-found.json";

const random_answers = [
  "what is the freakiest thing you have done?",
  "what are ur plans this weekend?",
  "spill some tea",
  "do you have cute friends for me lmao",
  "i have covid, any TV show I should watch?",
  "your biggest insecurity?",
  "your biggest fear?",
  "your type of music?",
  "biggest red flags for a girl?",
];

class Linkinbio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: false,
      loading: true,
      input_answer: "",
      storypost: null,
      index_random_answer: Math.floor(Math.random() * random_answers?.length),
    };
  }

  componentDidMount() {
    let question_id = this.props?.params?.question_id || "";
    if (question_id.length > 1) {
      axios
        .get(
          "https://askme-api.herokuapp.com/api/storyposts/" +
            question_id.replace("/", "")
        )
        .then((res) => {
          this.setState({
            storypost: res.data?.entity,
            loading: false,
            origin: this.props?.params?.origin,
          });

          document.title = res.data?.entity
            ? res.data?.entity?.user?.name +
              " - " +
              res.data?.entity?.title +
              " - AskMe"
            : "AskMe - Not found";
        });
    }
  }

  onSendAnswer() {
    if (this.state.input_answer) {
      axios
        .post(
          "https://askme-api.herokuapp.com/api/storyposts/message/" +
            this.state.storypost?._id,
          { content: this.state.input_answer, session_id: 1 }
        )
        .then((res) => {
          if (res?.data?.success) {
            this.setState({ input_answer: "" });
          }
        });
    }
  }
  render() {
    var is_light_mode = this.state.user?.theme_config_v1?.is_light_mode;
    return (
      <React.Fragment>
        <div
          id={"body_link_in_bio"}
          style={{
            minHeight: "100vh",
            backgroundColor: "#f5f5f5",
            backgroundImage:
              "url(" + this.state.storypost?.background_uri + ") ",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            textAlign: "center",
            backgroundSize: "cover",
          }}
        >
          {/* <RWebShare
            data={{
              text: "Check out " + this.state.storypost?.user?.name + "'s links",
              url: "https://on.natgeo.com/2zHaNup",
              title: this.state.storypost?.user?.name,
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <div
              style={{
                height: 35,
                width: 35,
                position: "fixed",
                backgroundColor: "#fff",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 50,
                top: 50,
                right: 50,
                boxShadow: "0 .5rem 1rem rgba(0,0,0,.05)",
                paddingTop: 5,
              }}
            >
              <i className="fas fa-share-alt " style={{ margin: "0 auto" }}></i>
            </div>
          </RWebShare> */}

          {this.state.loading ? (
            <Lottie
              animationData={loadingLottie}
              play
              loop
              style={{ width: 100, height: 100, alignSelf: "center" }}
            />
          ) : this.state.storypost ? (
            <div
              className="col-lg-6 col-md-12 col-sm-12 col-12 mx-auto"
              style={{
                zIndex: 1,
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div
                  style={{
                    margin: 13,
                    marginBottom: 0,
                    marginTop: 20,
                    borderRadius: "15px 15px 0px 0px",
                    padding: 13,
                    display: "flex",
                    flexDirection: "row",
                    background: "rgba(0,0,0,0.2)",
                  }}
                >
                  {this.state.storypost?.user?.profilePicture && (
                    <img
                      src={this.state.storypost?.user?.profilePicture}
                      style={{
                        height: 50,
                        width: 50,
                        borderRadius: 120,
                        backgroundColor: "rgba(255,255,255,0.5)",
                        objectFit: "cover",
                        boxShadow: "0 .5rem 0.5rem rgba(0,0,0,.05)",
                      }}
                    />
                  )}
                  <div
                    style={{
                      marginBottom: 0,
                      marginLeft: 15,
                    }}
                  >
                    <p
                      className="font-weight-bolder"
                      style={{
                        color: is_light_mode ? COLORS.grey_5 : "#fff",
                        textAlign: "left",
                      }}
                    >
                      {this.state.storypost?.title}
                    </p>
                    <h6
                      className="font-weight-bolder my-2"
                      style={{
                        color: is_light_mode ? COLORS.grey_5 : "#fff",
                        fontSize: 12,
                        textTransform: "uppercase",
                      }}
                    >
                      {this.state.storypost?.user?.bio}
                    </h6>
                  </div>
                  {(this.state.user?.links_list || []).map((item) => (
                    <a href={item.link} style={{ textDecoration: "none" }}>
                      <div
                        className="hvr-grow"
                        style={{
                          backgroundColor: is_light_mode
                            ? "rgba(255,255,255,1)"
                            : "rgba(0,0,0,0.8)",
                          boxShadow: "0 .5rem 1rem rgba(0,0,0,.05)",
                          marginBottom: 13,
                          borderRadius: 2,
                          paddingTop: 25,
                          paddingBottom: 5,
                          paddingLeft: 8,
                          paddingRight: 8,
                        }}
                      >
                        <p
                          style={{
                            color: is_light_mode ? COLORS.grey_5 : "#fff",
                            textDecorationLine: "none",
                            fontWeight: "600",
                          }}
                        >
                          {item.title || item.link.replace("https://", "")}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
                <div
                  style={{
                    background: "#000",
                    margin: 13,
                    marginTop: 0,
                    borderRadius: "0px 0px 15px 15px",
                  }}
                >
                  <textarea
                    value={this.state.input_answer}
                    ref={(ref) => (this.textarea = ref)}
                    onChange={(e) =>
                      this.setState({ input_answer: e.target.value })
                    }
                    className="storypost_textarea"
                    placeholder="type here..."
                    style={{
                      color: "#fff",
                      width: "100%",
                      padding: 15,
                      fontWeight: "600",
                    }}
                  ></textarea>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      padding: 15,
                      justifyContent: "flex-end",
                    }}
                  >
                    <>
                      {this.state.input_answer?.length == 0 && (
                        <div
                          style={{
                            marginRight: 15,
                            backgroundColor: "rgba(255,255,255,0.12)",
                            alignItems: "center",
                            borderRadius: 8,
                            maxWidth: 200,
                            paddingRight: 15,
                            paddingLeft: 15,
                          }}
                        >
                          <p
                            className="textclamped"
                            style={{
                              fontSize: 11,
                              paddingTop: 2,
                              margin: 0,
                              fontWeight: "600",
                              color: "rgba(255,255,255,0.6)",
                            }}
                          >
                            {random_answers[this.state.index_random_answer]}
                          </p>
                        </div>
                      )}
                      <div
                        onClick={() =>
                          this.setState({
                            input_answer:
                              random_answers[
                                Math.floor(
                                  Math.random() * random_answers?.length
                                )
                              ],
                          })
                        }
                        style={{
                          backgroundColor: "rgba(255,255,255,0.3)",
                          alignSelf: "flex-end",
                          display: "inline-block",
                          borderRadius: 100,
                          height: 40,
                          width: 40,
                          border: "2px solid rgba(255,255,255,0.6)",
                        }}
                      >
                        <p style={{ lineHeight: "36px" }}>🎲</p>
                      </div>
                    </>
                    {this.state.input_answer?.length > 0 && (
                      <>
                        <div
                          onClick={() => this.onSendAnswer()}
                          style={{
                            backgroundColor: "rgba(255,255,255,1)",
                            alignSelf: "flex-end",
                            display: "inline-block",
                            marginLeft: 8,
                            borderRadius: 100,
                            height: 40,
                            width: 40,
                            border: "2px solid rgba(255,255,255,0.9)",
                          }}
                        >
                          <span
                            style={{ lineHeight: "35px" }}
                            className="fas fa-paper-plane"
                            color="#000"
                          ></span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <p
                  className="display-4 font-weight-bolder my-2"
                  style={{
                    fontWeight: "500",
                    color: "rgba(255,255,255,0.6)",
                    fontSize: 14,
                  }}
                >
                  {this.state.storypost?.user?.name} posted this from the{" "}
                  <a
                    style={{
                      color: "rgba(255,255,255,0.9)",
                      fontWeight: "700",
                    }}
                    href="https://apps.apple.com/us/app/askme-anonymous-q-a/id1640939005"
                  >
                    AskMe
                  </a>{" "}
                  app .
                </p>
              </div>
              {this.state.origin !== "preview" && (
                <a
                  href="https://apps.apple.com/us/app/askme-anonymous-q-a/id1640939005"
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    marginBottom: 90,
                  }}
                >
                  <div
                    style={{
                      alignSelf: "center",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#fff",
                        justifyContent: "center",
                        borderRadius: 50,
                        padding: 15,
                        borderBottomColor: COLORS.grey_4,
                        borderBottomWidth: 10,
                      }}
                    >
                      <p
                        style={{ margin: 0, fontWeight: "600", color: "#000" }}
                      >
                        tap to make your own
                      </p>
                    </div>
                  </div>
                </a>
              )}
            </div>
          ) : (
            <>
              <Lottie
                animationData={notFoundLottie}
                play
                loop
                style={{ width: 300, height: 300 }}
              />
              <h2 style={{ padding: 10, marginTop: -30 }}>
                Oops, this page doesn't exist.
              </h2>
            </>
          )}
        </div>
        <div></div>
      </React.Fragment>
    );
  }
}

export default withParams(Linkinbio);
