import { useNavigate, useParams } from "react-router-dom";
// https://github.com/remix-run/react-router/issues/8146#issuecomment-947860640
// in hocs.js
export function withNavigation(Component) {
  return (props) => <Component {...props} navigate={useNavigate()} />;
}

export function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}
