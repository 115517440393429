import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import { forward_unicode_font } from "../_helpers/fonts";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class CardFont extends React.Component {
  render() {
    return (
      <Card
        className="hoverable cardtextdark"
        style={{
          marginTop: 8,
          marginBottom: 8,
        }}
        onClick={() => this.props.onPress()}
      >
        <CardBody>
          <CardTitle tag="h5" style={{ color: "#fff" }}></CardTitle>
          <CardText style={{ color: "#fff" }}>{this.props.text} </CardText>
          <Button size="sm" color="primary">
            Click to copy
          </Button>
        </CardBody>
      </Card>
    );
  }
}
export default class BioText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input_text: "",
    };
    console.log(forward_unicode_font("test"));
  }
  componentDidMount() {
    document.title = "Instagram Fonts Generator- 𝓒𝓸𝓹𝔂 𝓪𝓷𝓭 𝓹𝓪𝓼𝓽𝓮";
  }
  render() {
    return (
      <div
        style={{
          backgroundColor: "#121212",
          display: "flex",
          flexDirection: "column",
        }}
        className="fullHeight"
      >
        <ToastContainer autoClose={1000} theme="dark" />

        <div
          style={{
            flex: 0.8,
            paddingTop: 8,
            overflowY: "scroll",
          }}
        >
          <Container>
            <h5
              className="display-1"
              style={{
                color: "#fff",
                fontSize: 25,
                fontWeight: 800,
                marginTop: 20,
              }}
            >
              Fancy Bio Fonts
            </h5>
            <h4
              className="display-5"
              style={{
                color: "#fff",
                marginBottom: 20,
                fontSize: 16,
                fontWeight: 500,
                color: "rgba(255,255,255,0.5)",
              }}
            >
              Just type your bio and copy paste to your instagram account.{" "}
            </h4>
            <Row>
              {forward_unicode_font(
                this.state.input_text || "My fancy bio..."
              ).map((item, index) => {
                return (
                  <Col lg={4} md={6} xs={12} key={item + index}>
                    <CardFont
                      text={item || "My fancy bio..."}
                      onPress={() => {
                        navigator.clipboard.writeText(item || "");
                        toast.success("Text copied!");
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
        <div
          className="shadow-lg"
          style={{
            flex: 0.2,
            backgroundColor: "rgba(255,255,255,0.12)",
            paddingTop: 15,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Container
            style={{
              display: "flex",
              flexDirection: "column",

              flex: 1,
            }}
          >
            <Row style={{ flex: 1 }}>
              <Col>
                <Input
                  className="dark_input"
                  type="textarea"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.08)",
                    borderColor: "rgba(255,255,255,0.16)",
                    color: "#fff",
                  }}
                  plac
                  onChange={(e) => {
                    this.setState({ input_text: e.target.value });
                  }}
                  placeholder="Type something here..."
                />
              </Col>
            </Row>
            <p
              style={{
                fontWeight: "600",
                color: "rgba(255,255,255,0.16)",
                textAlign: "center",
                fontSize: 13,
                marginTop: 3,
              }}
            >
              Made with {"♥"} {"  "}by{" "}
              <a
                style={{ color: "rgba(255,255,255,0.32)", fontWeight: "700" }}
                href="https://www.blip.bio"
              >
                Blip
              </a>{" "}
              and{" "}
              <a
                style={{ color: "rgba(255,255,255,0.32)", fontWeight: "700" }}
                href="https://chelle.studio/"
              >
                ChelleStudio
              </a>
              .
            </p>
          </Container>
        </div>
      </div>
    );
  }
}
