import React from "react";
export default class TermsOfService extends React.Component {
  render() {
    return (
      <div
        className="col-lg-6 col-md-12 col-sm-12 col-12 mx-auto"
        style={{
          zIndex: 1,
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "space-between",
          paddingTop: 21,
          paddingLeft: 13,
          paddingRight: 13,
        }}
      >
        <h4>AskMe - END USER LICENSE AGREEMENT</h4>
        <p style={{ color: "#000", fontWeight: "500" }}>
          <br />
          Last updated August 21, 2022
          <br />
          AskMe is licensed to You (End-User) by AskMe, for use only under the
          terms of this License Agreement.
          <br />
          <br />
          By downloading the Application from the Apple AppStore, and any update
          thereto (as permitted by this License Agreement), You indicate that
          You agree to be bound by all of the terms and conditions of this
          License Agreement, and that You accept this License Agreement.
          <br />
          <br />
          The parties of this License Agreement acknowledge that Apple is not a
          Party to this License Agreement and is not bound by any provisions or
          obligations with regard to the Application, such as warranty,
          liability, maintenance and support thereof. AskMe, not Apple, is
          solely responsible for the licensed Application and the content
          thereof.
          <br />
          <br />
          This License Agreement may not provide for usage rules for the
          Application that are in conflict with the latest App Store Terms of
          Service. AskMe acknowledges that it had the opportunity to review said
          terms and this License Agreement is not conflicting with them.
          <br />
          <br />
          All rights not expressly granted to You are reserved.
          <br />
          <br />
          <br />
          1. THE APPLICATION
          <br />
          <br />
          AskMe (hereinafter: Application) is a piece of software created to
          help people finding friends - and customized for Apple mobile devices.
          It is used to find friends.
          <br />
          <br />
          The Application is not tailored to comply with industry-specific
          regulations (Health Insurance Portability and Accountability Act
          (HIPAA), Federal Information Security Management Act (FISMA), etc.),
          so if your interactions would be subjected to such laws, you may not
          use this Application. You may not use the Application in a way that
          would violate the Gramm-Leach-Bliley Act (GLBA).
          <br />
          <br />
          <br />
          2. SCOPE OF LICENSE
          <br />
          <br />
          2.1 You are given a non-transferable, non-exclusive, non-sublicensable
          license to install and use the Licensed Application on any
          Apple-branded Products that You (End-User) own or control and as
          permitted by the Usage Rules set forth in this section and the App
          Store Terms of Service, with the exception that such licensed
          Application may be accessed and used by other accounts associated with
          You (End-User, The Purchaser) via Family Sharing or volume purchasing.
          <br />
          <br />
          2.2 This license will also govern any updates of the Application
          provided by Licensor that replace, repair, and/or supplement the first
          Application, unless a separate license is provided for such update in
          which case the terms of that new license will govern.
          <br />
          <br />
          2.3 You may not share or make the Application available to third
          parties (unless to the degree allowed by the Apple Terms and
          Conditions, and with AskMe's prior written consent), sell, rent, lend,
          lease or otherwise redistribute the Application.
          <br />
          <br />
          2.4 You may not reverse engineer, translate, disassemble, integrate,
          decompile, integrate, remove, modify, combine, create derivative works
          or updates of, adapt, or attempt to derive the source code of the
          Application, or any part thereof (except with AskMe's prior written
          consent).
          <br />
          <br />
          2.5 You may not copy (excluding when expressly authorized by this
          license and the Usage Rules) or alter the Application or portions
          thereof. You may create and store copies only on devices that You own
          or control for backup keeping under the terms of this license, the App
          Store Terms of Service, and any other terms and conditions that apply
          to the device or software used. You may not remove any intellectual
          property notices. You acknowledge that no unauthorized third parties
          may gain access to these copies at any time.
          <br />
          <br />
          2.6 Violations of the obligations mentioned above, as well as the
          attempt of such infringement, may be subject to prosecution and
          damages.
          <br />
          <br />
          2.7 Licensor reserves the right to modify the terms and conditions of
          licensing.
          <br />
          <br />
          2.8 Nothing in this license should be interpreted to restrict
          third-party terms. When using the Application, You must ensure that
          You comply with applicable third-party terms and conditions.
          <br />
          <br />
          <br />
          3. TECHNICAL REQUIREMENTS
          <br />
          <br />
          3.1 The Application requires a firmware version 1.0.0 or higher.
          Licensor recommends using the latest version of the firmware.
          <br />
          <br />
          3.2 Licensor attempts to keep the Application updated so that it
          complies with modified/new versions of the firmware and new hardware.
          You are not granted rights to claim such an update.
          <br />
          <br />
          3.3 You acknowledge that it is Your responsibility to confirm and
          determine that the app end-user device on which You intend to use the
          Application satisfies the technical specifications mentioned above.
          <br />
          <br />
          3.4 Licensor reserves the right to modify the technical specifications
          as it sees appropriate at any time.
          <br />
          <br />
          <br />
          <br />
          4. MAINTENANCE AND SUPPORT
          <br />
          <br />
          4.1 The Licensor is solely responsible for providing any maintenance
          and support services for this licensed Application. You can reach the
          Licensor at the email address listed in the App Store Overview for
          this licensed Application.
          <br />
          <br />
          4.2 AskMe and the End-User acknowledge that Apple has no obligation
          whatsoever to furnish any maintenance and support services with
          respect to the licensed Application.
          <br />
          <br />
          <br />
          5. USE OF DATA
          <br />
          <br />
          You acknowledge that Licensor will be able to access and adjust Your
          downloaded licensed Application content and Your personal information,
          and that Licensor's use of such material and information is subject to
          Your legal agreements with Licensor and Licensor's privacy policy:
          __________.
          <br />
          <br />
          <br />
          6. End-user license agreement
          <br />
          <br />
          6.1 WHO CAN USE AskMe ?<br />
          <br />
          You may browse the Site and view content without registering, but as a
          condition to using certain aspects of the Services, you are required
          to register with AskMe and represent, warrant and covenant that you
          provide AskMe with accurate and complete registration information
          (including, but not limited to Snapchat Bitmoji and display name, user
          name (“User Name”), e-mail address and/or mobile telephone number,
          profile name, picture and a password you will use to access the
          Services) and to keep your registration information accurate and
          up-to-date. Failure to do so shall constitute a breach of these Terms,
          which may result in immediate termination of your AskMe account. We
          recommend, but do not require, that you use your own name as your User
          Name so your friends can recognize you more easily.
          <br />
          <br />
          AskMe will have the right to use your registration information in
          connection with servicing and operating the AskMe Platform. You agree
          (i) to restrict access by any other person or entity to your password
          or other login information, (ii) to not knowingly use the name or
          email of any other person without authorization, (iii) to not use an
          email or profile name that is profane, offensive or otherwise
          inappropriate, (iv) to not allow any third party to use your login
          information, registration information or User account, and (v) to
          notify AskMe of any breach of security by promptly sending AskMe an
          e-mail to support@AskMe.com. You agree that you will be liable for all
          activities that occur under your User account, even if such activities
          were not committed by you. AskMe is not responsible for any loss or
          damage as a result of someone else using your User account,
          registration information or password with or without your knowledge.
          You acknowledge and agree that certain User account information (such
          as your account profile name and/or picture) that you provide in
          connection with the registration of your account will be publicly
          viewable by all Users of the AskMe Platform.
          <br />
          <br />
          We want our Service to be as open and inclusive as possible, but we
          also want it to be safe, secure, and in accordance with the law. So,
          we need you to commit to a few restrictions in order to be part of the
          AskMe community.
          <br />
          <br />
          THE SERVICES ARE NOT FOR PERSONS UNDER THE AGE OF 13 OR FOR ANY USERS
          PREVIOUSLY SUSPENDED OR REMOVED FROM THE SERVICES BY AskMe. IF YOU ARE
          UNDER 13 YEARS OF AGE, THEN YOU MUST NOT USE OR ACCESS THE SERVICES AT
          ANY TIME OR IN ANY MANNER. Furthermore, by using the Services, you
          affirm that either you are at least 18 years of age or have been
          authorized to use the Services by your parent or legal guardian who is
          at least 18 years of age and entered into these Terms on your behalf.
          If you are using the Services on behalf of an entity, then you
          represent and warrant that you have the authority to bind that entity
          or organization to these Terms and you agree to be bound by these
          Terms on behalf of that entity or organization.
          <br />
          <br />
          In addition, you must not be prohibited from receiving any aspect of
          our Services under applicable laws or engaging in payments related to
          the Services. For example, you must not be a convicted sex offender or
          on an applicable denied party listing.
          <br />
          <br />
          AskMe complies with all applicable laws, including those pertaining to
          the protection of minors. We do not permit the AskMe app to be used to
          publish, submit/receive, upload/download, post, use, copy or otherwise
          produce, transmit, distribute or store child pornography or content
          otherwise depicting child exploitation.
          <br />
          <br />
          We have zero tolerance for predatory behavior on AskMe, and we will
          report any discovered violation of the prohibition on child
          pornography or other instances of child exploitation to the National
          Center for Missing and Exploited Children, who works with global law
          enforcement agencies to investigate such unlawful behavior. We will
          also take steps to remove child pornography or content otherwise
          depicting child exploitation (or otherwise block access to the content
          determined to contain child pornography or exploitation) from the
          AskMe Platform. Suspected violations of this prohibition may be
          reported to AskMe at the following e-mail address: legal@AskMe.com.
          For more information about online safety, visit www.ncmec.org.
          <br />
          <br />
          Finally, we must not have previously disabled your account for
          violation of law or any of our policies.
          <br />
          <br />
          6.2 USER GENERATED CONTENT
          <br />
          The Application may invite you to chat, contribute to, or participate
          in blogs, message boards, online forums, and other functionality, and
          may provide you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or in the Application, including but not limited to
          text, writings, video, audio, photographs, graphics, comments,
          suggestions, or personal information or other material (collectively,
          "Contributions"). Contributions may be viewable by other users of the
          Application and through third-party websites or applications. As such,
          any Contributions you transmit may be treated as non-confidential and
          non-proprietary. When you create or make available any Contributions,
          you thereby represent and warrant that:
          <br />
          <br />
          1. The creation, distribution, transmission, public display, or
          performance, and the accessing, downloading, or copying of your
          Contributions do not and will not infringe the proprietary rights,
          including but not limited to the copyright, patent, trademark, trade
          secret, or moral rights of any third party.
          <br />
          2. You are the creator and owner of or have the necessary licenses,
          rights, consents, releases, and permissions to use and to authorize
          us, the Application, and other users of the Application to use your
          Contributions in any manner contemplated by the Application and these
          Terms of Use.
          <br />
          3. You have the written consent, release, and/or permission of each
          and every identifiable individual person in your Contributions to use
          the name or likeness or each and every such identifiable individual
          person to enable inclusion and use of your Contributions in any manner
          contemplated by the Application and these Terms of Use.
          <br />
          4. Your Contributions are not false, inaccurate, or misleading.
          <br />
          5. Your Contributions are not unsolicited or unauthorized advertising,
          promotional materials, pyramid schemes, chain letters, spam, mass
          mailings, or other forms of solicitation.
          <br />
          6. Your Contributions are not obscene, lewd, lascivious, filthy,
          violent, harassing, libelous, slanderous, or otherwise objectionable
          (as determined by us).
          <br />
          7. Your Contributions do not ridicule, mock, disparage, intimidate, or
          abuse anyone.
          <br />
          8. Your Contributions are not used to harass or threaten (in the legal
          sense of those terms) any other person and to promote violence against
          a specific person or class of people.
          <br />
          9. Your Contributions do not violate any applicable law, regulation,
          or rule.
          <br />
          10. Your Contributions do not violate the privacy or publicity rights
          of any third party.
          <br />
          11. Your Contributions do not contain any material that solicits
          personal information from anyone under the age of 18 or exploits
          people under the age of 18 in a sexual or violent manner.
          <br />
          12. Your Contributions do not violate any applicable law concerning
          child pornography, or otherwise intended to protect the health or
          well-being of minors.
          <br />
          13. Your Contributions do not include any offensive comments that are
          connected to race, national origin, gender, sexual preference, or
          physical handicap.
          <br />
          14. Your Contributions do not include any predatory or pornographic
          content. <br />
          15. Your Contributions do not otherwise violate, or link to material
          that violates, any provision of these Terms of Use, or any applicable
          law or regulation.
          <br />
          <br />
          Any use of the Application in violation of the foregoing violates
          these Terms of Use and may result in, among other things, termination
          or suspension of your rights to use the Application.
          <br />
          <br />
          The AskMe Platform enables Users to communicate and interact with
          other Users, including, without limitation, sending messaging between
          Users. You are solely responsible for all of your interactions with
          the AskMe Platform and other Users/User Content on or through the
          AskMe Platform. In your interactions with other Users/User Content,
          you agree to conduct yourself professionally, civilly and respectfully
          at all times. You acknowledge and agree that AskMe will not be
          responsible for the actions of any Users with respect to any other
          User or User Content, and that AskMe does not actively monitor or
          police the specific interactions between Users of the AskMe Platform
          and other Users or User Content (and has no obligation to do so). You
          hereby release, disclaim and hold AskMe harmless from and against any
          and all liability resulting from a User’s interaction with, or conduct
          towards, any other User or User Content (whether online or offline).
          Notwithstanding the foregoing, AskMe reserves the right to determine,
          in its sole discretion, what constitutes harassment, mischief, or
          unacceptable conduct with or towards other Users or User Content, and
          where that has occurred, and may in its sole discretion, partially or
          completely deny, suspend or terminate access to the AskMe Platform to
          any User that it determines has engaged in such behavior.
          <br />
          <br />
          <br />
          <br />
          7. CONTRIBUTION LICENSE
          <br />
          <br />
          By posting your Contributions to any part of the Application or making
          Contributions accessible to the Application by linking your account
          from the Application to any of your social networking accounts, you
          automatically grant, and you represent and warrant that you have the
          right to grant, to us an unrestricted, unlimited, irrevocable,
          perpetual, non-exclusive, transferable, royalty-free, fully-paid,
          worldwide right, and license to host, use copy, reproduce, disclose,
          sell, resell, publish, broad cast, retitle, archive, store, cache,
          publicly display, reformat, translate, transmit, excerpt (in whole or
          in part), and distribute such Contributions (including, without
          limitation, your image and voice) for any purpose, commercial
          advertising, or otherwise, and to prepare derivative works of, or
          incorporate in other works, such as Contributions, and grant and
          authorize sublicenses of the foregoing. The use and distribution may
          occur in any media formats and through any media channels.
          <br />
          <br />
          This license will apply to any form, media, or technology now known or
          hereafter developed, and includes our use of your name, company name,
          and franchise name, as applicable, and any of the trademarks, service
          marks, trade names, logos, and personal and commercial images you
          provide. You waive all moral rights in your Contributions, and you
          warrant that moral rights have not otherwise been asserted in your
          Contributions.
          <br />
          <br />
          We do not assert any ownership over your Contributions. You retain
          full ownership of all of your Contributions and any intellectual
          property rights or other proprietary rights associated with your
          Contributions. We are not liable for any statements or representations
          in your Contributions provided by you in any area in the Application.
          You are solely responsible for your Contributions to the Application
          and you expressly agree to exonerate us from any and all
          responsibility and to refrain from any legal action against us
          regarding your Contributions.
          <br />
          <br />
          We have the right, in our sole and absolute discretion, (1) to edit,
          redact, or otherwise change any Contributions; (2) to re-categorize
          any Contributions to place them in more appropriate locations in the
          Application; and (3) to pre-screen or delete any Contributions at any
          time and for any reason, without notice. We have no obligation to
          monitor your Contributions.
          <br />
          <br />
          <br />
          8. LIABILITY
          <br />
          <br />
          8.1 Licensor's responsibility in the case of violation of obligations
          and tort shall be limited to intent and gross negligence. Only in case
          of a breach of essential contractual duties (cardinal obligations),
          Licensor shall also be liable in case of slight negligence. In any
          case, liability shall be limited to the foreseeable, contractually
          typical damages. The limitation mentioned above does not apply to
          injuries to life, limb, or health.
          <br />
          <br />
          8.2 Licensor takes no accountability or responsibility for any damages
          caused due to a breach of duties according to Section 2 of this
          Agreement. To avoid data loss, You are required to make use of backup
          functions of the Application to the extent allowed by applicable
          third-party terms and conditions of use. You are aware that in case of
          alterations or manipulations of the Application, You will not have
          access to licensed Application.
          <br />
          <br />
          <br />
          9. WARRANTY
          <br />
          <br />
          9.1 Licensor warrants that the Application is free of spyware, trojan
          horses, viruses, or any other malware at the time of Your download.
          Licensor warrants that the Application works as described in the user
          documentation.
          <br />
          <br />
          9.2 No warranty is provided for the Application that is not executable
          on the device, that has been unauthorizedly modified, handled
          inappropriately or culpably, combined or installed with inappropriate
          hardware or software, used with inappropriate accessories, regardless
          if by Yourself or by third parties, or if there are any other reasons
          outside of AskMe's sphere of influence that affect the executability
          of the Application.
          <br />
          <br />
          9.3 You are required to inspect the Application immediately after
          installing it and notify AskMe about issues discovered without delay
          by e-mail provided in Product Claims. The defect report will be taken
          into consideration and further investigated if it has been mailed
          within a period of thirty (30) days after discovery.
          <br />
          <br />
          9.4 If we confirm that the Application is defective, AskMe reserves a
          choice to remedy the situation either by means of solving the defect
          or substitute delivery.
          <br />
          <br />
          9.5 In the event of any failure of the Application to conform to any
          applicable warranty, You may notify the App-Store-Operator, and Your
          Application purchase price will be refunded to You. To the maximum
          extent permitted by applicable law, the App-Store-Operator will have
          no other warranty obligation whatsoever with respect to the App, and
          any other losses, claims, damages, liabilities, e xpenses and costs
          attributable to any negligence to adhere to any warranty.
          <br />
          <br />
          9.6 If the user is an entrepreneur, any claim based on faults expires
          after a statutory period of limitation amounting to twelve (12) months
          after the Application was made available to the user. The statutory
          periods of limitation given by law apply for users who are consumers.
          <br />
          <br />
          10. PRODUCT CLAIMS
          <br />
          <br />
          AskMe and the End-User acknowledge that AskMe, and not Apple, is
          responsible for addressing any claims of the End-User or any third
          party relating to the licensed Application or the End-User’s
          possession and/or use of that licensed Application, including, but not
          limited to:
          <br />
          <br />
          (i) product liability claims;
          <br />
          <br />
          (ii) any claim that the licensed Application fails to conform to any
          applicable legal or regulatory requirement; and
          <br />
          <br />
          (iii) claims arising under consumer protection, privacy, or similar
          legislation, including in connection with Your Licensed Application’s
          use of the HealthKit and HomeKit.
          <br />
          <br />
          <br />
          11. LEGAL COMPLIANCE
          <br />
          <br />
          You represent and warrant that You are not located in a country that
          is subject to a U.S. Government embargo, or that has been designated
          by the U.S. Government as a "terrorist supporting" country; and that
          You are not listed on any U.S. Government list of prohibited or
          restricted parties.
          <br />
          <br />
          <br />
          12. CONTACT INFORMATION <br />
          <br />
          For general inquiries, complaints, questions or claims concerning the
          licensed Application, please contact:
          <br /> <br />
          __________
          <br />
          112 place de l eglise
          <br />
          Berat, 1 31370
          <br />
          France
          <br />
          overflozz@gmail.com
          <br />
          <br />
          <br />
          13. TERMINATION
          <br />
          <br />
          The license is valid until terminated by AskMe or by You. Your rights
          under this license will terminate automatically and without notice
          from AskMe if You fail to adhere to any term(s) of this license. Upon
          License termination, You shall stop all use of the Application, and
          destroy all copies, full or partial, of the Application.
          <br />
          <br />
          <br />
          14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY
          <br />
          <br />
          AskMe represents and warrants that AskMe will comply with applicable
          third-party terms of agreement when using licensed Application.
          <br />
          <br />
          In Accordance with Section 9 of the "Instructions for Minimum Terms of
          Developer's End-User License Agreement," Apple and Apple's
          subsidiaries shall be third-party beneficiaries of this End User
          License Agreement and - upon Your acceptance of the terms and
          conditions of this license agreement, Apple will have the right (and
          will be deemed to have accepted the right) to enforce this End User
          License Agreement against You as a third-party beneficiary thereof.
          <br />
          <br />
          <br />
          15. INTELLECTUAL PROPERTY RIGHTS
          <br />
          <br />
          AskMe and the End-User acknowledge that, in the event of any
          third-party claim that the licensed Application or the End-User's
          possession and use of that licensed Application infringes on the third
          party's intellectual property rights, AskMe, and not Apple, will be
          solely responsible for the investigation, defense, settlement and
          discharge or any such intellectual property infringement claims.
          <br />
          <br />
          <br />
          16. APPLICABLE LAW
          <br />
          <br />
          This license agreement is governed by the laws of France excluding its
          conflicts of law rules.
          <br />
          <br />
          17. DIAMONDS
          <br />
          <br />
          In order to reward Users for certain activities, AskMe may give you
          the opportunity to earn, and use credits in AskMe’s in-app virtual
          currency (“Diamonds”) that may be used to acquire virtual goods and
          services solely with the Services. Diamonds are not real money, do not
          have monetary value, and may never be redeemed for “real world” money,
          or other items of monetary value from outside the Services without our
          written permission. While we may use terms like “earn”, “trade-in”, or
          “cash-out” in reference to the Diamonds, we do so only for convenience
          and such terms in no way indicate that Diamonds have monetary value or
          are real money. You acknowledge that Diamonds are not real currency
          and are not redeemable for any sum of money from us at any time. We
          make no guarantee as to the nature, quality, or value of the features
          of the Services or any third-party good or services that will be
          accessible through the use of Diamonds, or the availability or supply
          of Diamonds. Diamonds obtained via the Services are provided to you
          under a limited, personal, revocable, non-transferable,
          non-sublicenseable license to use within the Services. Diamonds may
          not be transferred to other accounts or resold in any manner,
          including, without limitation, by means of any direct sale or auction
          service. You have no property interest, right or title in or to any
          Diamonds appearing or originating in the Services or any other
          attributes associated with use of the Services. Any “virtual currency”
          balance shown in your account does not constitute a real-world balance
          or reflect any stored value, but instead constitutes a measurement of
          the extent of your license.
          <br />
          <br />
          All Diamonds are forfeited if your account or access to the Services
          is terminated or suspended for any reason, in our sole and absolute
          discretion, or if we discontinue availability of some or all of the
          Services. We may at any time expire free or promotional Diamonds given
          to you.
          <br />
          <br />
          We have no liability for hacking or loss of your Diamonds or any goods
          or services obtained using Diamonds. We have no obligation to, and
          will not, reimburse you for any Diamonds that are lost due to your
          violation of these Terms. We reserve the right, without prior
          notification, to limit the quantity of Diamonds and to refuse to
          provide you with any Diamonds. Price, exchangeability and availability
          of Diamonds are determined by us in our sole discretion and are
          subject to change without notice. You agree that we have the absolute
          right to manage, distribute, regulate, control, modify, or eliminate
          Diamonds as we see fit in our sole discretion, and that we will have
          no liability for exercising such right.
          <br />
          <br />
          You agree that under no circumstances are we liable to you for any
          damages or claims that may arise from the loss or use of your Diamonds
          regardless of the circumstances. You absolve us of any responsibility
          to maintain or update your account Diamonds balance. However, if there
          is a loss of Diamonds in your account due to technical or operational
          problems with the Services, we will refund the lost Diamonds once the
          loss has been verified. Without limiting any of the foregoing, our
          maximum liability or responsibility to you is to refund the Diamonds
          lost.
          <br />
          <br />
          You may from time to time be presented with opportunities to redeem
          Diamonds. We will, in our sole discretion, determine and communicate
          the availability and exchange rate for any Diamonds, which may be
          modified at any time. We reserve the right to cancel, restrict or
          terminate Diamonds at any time for any reason. All redemptions are
          subject to these Terms and all limitations and requirements stated via
          the Services. All acquisitions and redemptions of Diamonds are final.
          Once Diamonds have been lost or spent, they will be subtracted from
          your account and cannot be refunded or returned, except in our sole
          discretion.
          <br />
          <br />
          18. MISCELLANEOUS
          <br />
          <br />
          18.1 If any of the terms of this agreement should be or become
          invalid, the validity of the remaining provisions shall not be
          affected. Invalid terms will be replaced by valid ones formulated in a
          way that will achieve the primary purpose.
          <br /> <br />
          18.2 Collateral agreements, changes and amendments are only valid if
          laid down in writing. The preceding clause can only be waived in
          writing.
          <br />
        </p>
      </div>
    );
  }
}
